import {
  f1,
  f2,
  f3,
  f4,
  f5,
  f6,
  f7,
  f8,
  f9,
  flag,
  p1,
  p2,
  p3,
  p4,
  p5,
  UserProfile1,
} from "../assets";
import {
  AllImageBoxIcons1,
  AllImageBoxIcons2,
  AllImageBoxIcons3,
  AllImageBoxIcons4,
  AllImageBoxIcons5,
  AllImageBoxIcons6,
} from "../components/general/AllImageBoxIcons";
import {
  AllImageIcons1,
  AllImageIcons2,
  AllImageIcons3,
  AllImageIcons4,
} from "../components/general/AllSvgs";

export const imageboxdata = [
  {
    icon: AllImageBoxIcons1,
    title: "Tutor Mode",
    text: "Learn with guided explanations to master each topic.",
    link: "/",
  },
  {
    icon: AllImageBoxIcons2,
    title: "Detailed Explanation",
    text: "Understand every answer with comprehensive explanations.",
    link: "/",
  },
  {
    icon: AllImageBoxIcons3,
    title: "Multiple Difficulty Levels",
    text: "Challenge yourself with varying levels of difficulty.",
    link: "/",
  },
  {
    icon: AllImageBoxIcons4,
    title: "Pause/Resume Exam",
    text: "Take control of your study time with pause and resume options.",
    link: "/",
  },
  {
    icon: AllImageBoxIcons5,
    title: "Analytics and Reporting",
    text: "Track your progress with detailed analytics and reports.",
    link: "/",
  },
  {
    icon: AllImageBoxIcons6,
    title: "Worldwide Access ",
    text: "Study anytime, anywhere with global access.",
    link: "/",
  },
];
export const aboudBoxData = [
  {
    icon: AllImageBoxIcons1,
    title: "Comprehensive Medical MCQ Bank",
    text: "Our extensive question bank includes thousands of questions that cover all major topics of the AMC syllabus. Each question is crafted to reflect the style and difficulty of the actual exam, ensuring you are well-prepared.",
    link: "/",
  },
  {
    icon: AllImageBoxIcons2,
    title: "Detailed Explanation",
    text: "We provide in-depth explanations and references for every question, helping you understand the concepts and logic behind each answer. This approach not only prepares you for the exam but also enhances your overall medical knowledge.",
    link: "/",
  },

  {
    icon: AllImageBoxIcons4,
    title: "Customizable Practice Tests",
    text: "With Q Bank Model, you can create personalized practice tests to focus on areas where you need the most improvement. Our flexible testing options allow you to tailor your study sessions to your specific needs.",
    link: "/",
  },
  {
    icon: AllImageBoxIcons5,
    title: "Performance Analytics",
    text: "Track your progress with our advanced analytics tools. Identify your strengths and weaknesses, and adjust your study plan accordingly. Our performance tracking ensures you are on the right path to success.",
    link: "/",
  },
];
export const examdata = [
  {
    icon: AllImageIcons1,
    title: "What is AMC?",
    text: "The Australian Medical Council (AMC) is responsible for assessing the qualifications of overseas-trained doctors and medical students who wish to practice medicine in Australia. It conducts exams to ensure candidates meet Australian medical standards.",
    btntext: "Learn More",
    link: "/",
  },
  {
    icon: AllImageIcons2,
    title: "How to Qualify for AMC?",
    text: "To qualify for the AMC exams, candidates must typically hold a primary medical degree from a recognized institution and meet other eligibility criteria set by the AMC. This includes demonstrating English language proficiency and potentially completing additional training or assessments.",
    btntext: "Learn More",
    link: "/",
  },
  {
    icon: AllImageIcons3,
    title: "AMC Course Outline",
    text: "The AMC MCQ Exam consists of multiple-choice questions designed to assess medical knowledge relevant to Australian medical practice standards. The exam covers various medical disciplines, including clinical medicine, ethics, and legal aspects of medicine.",
    btntext: "Learn More",
    link: "/",
  },
  {
    icon: AllImageIcons4,
    title: "AMC Useful Resources",
    text: "Preparing for the AMC exams requires access to comprehensive study materials and resources. These may include practice exams, study guides, and courses specifically tailored to the AMC exam format and content.",
    btntext: "Learn More",
    link: "/",
  },
];

export const testimonialData = [
  {
    rating: 5,
    parag: (
      <>
        The Qbank Model practice tests were spot on! They replicated the real
        exam experience, giving me the confidence I needed to succeed. I highly
        recommend it for anyone serious about passing the AMC CAT exam.
      </>
    ),
    // profile: p1,
    name: "Dr. Priya M",
    flag: f1,
    location: "India",
  },

  {
    rating: 5,
    parag: (
      <>
        I was struggling with my AMC exam prep until I found Qbank Model. The
        detailed explanations and progress tracking really helped me improve.
        I'm so glad I passed!
      </>
    ),
    // profile: p3,
    flag: f2,
    name: "Dr. James L",
    location: "United State",
  },

  {
    rating: 5,
    parag: (
      <>
        The AMC question bank on Qbank Model is unmatched! I felt fully prepared
        walking into the exam, and I couldn't have passed without it. Great
        platform!
      </>
    ),
    // profile: p4,
    flag: f4,
    name: "Dr. Emily R.",
    location: "Australia",
  },

  {
    rating: 5,
    parag: (
      <>
        Qbank Model made practicing for the AMC CAT exam so much easier! This
        platform kept me on track and I successfully cleared the exam. Highly
        recommend!
      </>
    ),
    // profile: p5,
    flag: f1,

    name: "Dr. Samira F.",
    location: "India",
  },
  {
    rating: 5,
    parag: (
      <>
        I was worried about retaking the exam, but Qbank Model gave me the boost
        I needed. The practice exams were a game-changer, and I finally passed!
      </>
    ),
    // profile: p5,
    flag: f1,

    name: "Dr. Rajiv T.",
    location: "India",
  },
  {
    rating: 5,
    parag: (
      <>
        The variety of questions and the detailed answers on Qbank Model were
        fantastic! It helped me strengthen my weak areas and ultimately pass the
        AMC CAT exam.
      </>
    ),
    // profile: p5,
    flag: f5,

    name: "Dr. Laura H.",
    location: "Germany",
  },
  {
    rating: 5,
    parag: (
      <>
        I can't thank Qbank Model enough! The platform was user-friendly, and
        the exam simulations helped me build confidence. Passing the AMC exam
        felt much easier.
      </>
    ),
    // profile: p5,
    flag: f4,

    name: "Dr. Paul V.",
    location: "Australia",
  },
  {
    rating: 5,
    parag: (
      <>
        The best thing about Qbank Model was the accurate feedback after each
        exam. It made a huge difference in my preparation. I passed the AMC CAT
        exam on my first try!
      </>
    ),
    // profile: p5,
    flag: f6,

    name: "Dr. Ayesha K.",
    location: "Pakistan",
  },
  {
    rating: 5,
    parag: (
      <>
        Qbank Model was a best option for my AMC preparation. The question bank
        was updated and aligned perfectly with the exam format. I couldn't have
        done it without this platform!
      </>
    ),
    // profile: p5,
    flag: f1,

    name: "Dr. Sandeep M.",
    location: "India",
  },
  {
    rating: 5,
    parag: (
      <>
        Qbank Model exceeded my expectations! The question bank was incredibly
        thorough and helped me stay focused. I passed the AMC CAT exam on my
        first try, and I owe it to this platform!
      </>
    ),
    // profile: p5,
    flag: f3,

    name: "Dr. Ahmed S.",
    location: "Egypt",
  },
  {
    rating: 5,
    parag: (
      <>
        Qbank Model was exactly what I needed to pass the AMC CAT exam. The
        question bank covered all the key topics, and the explanations were
        incredibly detailed. Highly recommend it to all medical graduates!
      </>
    ),
    // profile: p5,
    flag: f7,

    name: "Dr. Sofia G.",
    location: "United Kingdom",
  },
  {
    rating: 5,
    parag: (
      <>
        Thanks to Qbank Model, I felt more confident going into the AMC CAT
        exam. The practice questions and progress tracking made studying more
        efficient. I’m thrilled to have passed!
      </>
    ),
    // profile: p5,
    flag: f1,

    name: "Dr. Ali A.",
    location: "India",
  },
  {
    rating: 5,
    parag: (
      <>
        I had been searching for the perfect resource to help with my AMC
        preparation, and Qbank Model delivered! The platform was easy to use and
        helped me pass the exam on my second attempt.
      </>
    ),
    // profile: p5,
    flag: f8,

    name: "Dr. Reyna T.",
    location: "Philippines",
  },

  {
    rating: 5,
    parag: (
      <>
        Qbank Model AMC MCQs were incredibly helpful in strengthening my weak
        areas. The detailed answers and explanations allowed me to understand
        concepts better. I passed the exam confidently!
      </>
    ),
    // profile: p5,
    flag: f6,

    name: "Dr. Zara H.",
    location: "Pakistan",
  },
  {
    rating: 5,
    parag: (
      <>
        I had a great experience with Qbank Model. The platform was
        user-friendly, and the question bank really helped me prepare thoroughly
        for the AMC CAT exam. I passed with ease!
      </>
    ),
    // profile: p5,
    flag: f1,

    name: "Dr. Nikhil R.",
    location: "India",
  },

  {
    rating: 5,
    parag: (
      <>
        After failing my first AMC attempt, I found Qbank Model and it made all
        the difference. The timed practice exams gave me the confidence I
        needed. I passed on my second try!!
      </>
    ),
    // profile: p5,
    flag: f4,

    name: " Dr. Olivia B.",
    location: "New Zeland",
  },
  {
    rating: 5,
    parag: (
      <>
        Qbank Model is a must for anyone preparing for the AMC CAT exam. The
        questions were spot on, and the platform was so intuitive. It was key to
        my success.
      </>
    ),
    // profile: p5,
    flag: f1,

    name: "Dr. Ravi S.",
    location: "India",
  },

  {
    rating: 5,
    parag: (
      <>
        The quality of questions and the realistic test environment on Qbank
        Model were perfect for my exam prep. I’m so glad I chose this platform,
        as I passed the AMC CAT exam on my first try!
      </>
    ),
    // profile: p5,
    flag: f9,

    name: "Dr. Melissa C.",
    location: "Zimbabwe",
  },

  {
    rating: 5,
    parag: (
      <>
        I’m so grateful for Qbank Model. The detailed explanations helped me
        improve my understanding of complex topics, and I successfully passed
        the AMC CAT exam. Definitely worth it!
      </>
    ),
    // profile: p5,
    flag: f1,

    name: "Dr. Farhan E.",
    location: "India",
  },
];
