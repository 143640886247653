import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "../../api";
import PricingComponent from "../../components/pricing/PricingComponent";

const DashboardSubscriptions = () => {
  const [data, setData] = useState(null);
  const authorized = useSelector((state) => state.user.user.authorized);
  const [activeSubsription, setActiveSubscription] = useState(null);
  const getData = async () => {
    let response;
    try {
      response = await API.getAllSubscription();
      setData(response?.data?.data);

      // response = await API.getUserSummner();
      // setActiveSubscription(response?.data?.data?.userSubscriptionId);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const filderData = data?.filter((item) => item?.isLive);
  filderData?.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

  return (
    <div>
      {filderData ? (
        <PricingComponent
          hide={true}
          activeSubsription={activeSubsription}
          data={filderData}
          authorized={authorized}
        />
      ) : null}
    </div>
  );
};

export default DashboardSubscriptions;
