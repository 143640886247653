import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import InnerHeader from "../components/general/InnerHeader";
import MetaData from "../components/seo/MetaData";

const Terms = () => {
      
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div className="home w-full pagelayout">
      

        <MetaData
        title={"Terms and Conditions - Qbankmodel"}
        description={
          "Read Qbankmodel's Terms and Conditions. Understanding our terms ensures a smooth experience while using our AMC exam preparation platform."
        }
        keywords={
          "terms and conditions, Qbankmodel, AMC exam, user agreement, website usage"
        }
      />

        <InnerHeader text={"Terms and Conditions"} />

        <div className="max-w-[1200px] mx-auto w-full px-3  ">
          <p className="text-gray-600 mb-6 leading-relaxed">
            Welcome to <span className="text-blue-500">www.qbankmodel.com</span>. By accessing and using this website,
            you agree to comply with and be bound by the following terms and
            conditions. If you disagree with any part of these terms, please
            refrain from using our site.
          </p>

          <Section title="License to Use the Website">
            <p>
              Unless otherwise indicated, Q Bank Model and/or its licensors hold
              the intellectual property rights for the website and its content.
              All intellectual property rights are reserved. You are permitted
              to view and take notes on the questions for your personal exam
              preparation only.
            </p>
            <p className="mt-4">You are not allowed to:</p>
            <ul className="list-disc list-inside">
              <li>
                Download any content such as MCQs, questions, answers, or
                explanations for any use.
              </li>
              <li>
                Print any material from the website for purposes other than
                personal use.
              </li>
              <li>
                Republish content from this website on another website or
                platform.
              </li>
              <li>Sell, rent, or sub-license content from the website.</li>
              <li>Display any content from the website in public settings.</li>
              <li>
                Copy, duplicate, or exploit content from the website for
                commercial purposes.
              </li>
              <li>Alter or modify any material on the website.</li>
              <li>
                Redistribute any material from this website, except for content
                explicitly designated for redistribution.
              </li>
            </ul>
          </Section>

          <Section title="Acceptable Use">
            <p>
              You must not use this website in any manner that causes or could
              cause damage to the website or impair its availability or
              accessibility. Use of the site for any unlawful, illegal,
              fraudulent, or harmful purpose or activity is strictly prohibited.
            </p>
            <p className="mt-4">You must not use this website to:</p>
            <ul className="list-disc list-inside">
              <li>
                Copy, store, host, transmit, send, use, publish, or distribute
                any material that contains spyware, computer viruses, Trojan
                horses, worms, keystroke loggers, rootkits, or other malicious
                software.
              </li>
              <li>
                Conduct any systematic or automated data collection activities
                (including scraping, data mining, data extraction, and data
                harvesting) without Q Bank Model's explicit written consent.
              </li>
            </ul>
          </Section>

          <Section title="No Warranties">
            <p>
              This website is provided "as is" without any representations or
              warranties, express or implied. Q Bank Model makes no
              representations or warranties regarding the website or the
              information and materials provided.
            </p>
            <p className="mt-4">
              Specifically, Q Bank Model does not warrant that:
            </p>
            <ul className="list-disc list-inside">
              <li>
                This website will be continuously available, or available at
                all.
              </li>
              <li>
                The information on this website is complete, true, accurate, or
                non-misleading.
              </li>
            </ul>
            <p className="mt-4">
              Nothing on this website constitutes, or is meant to constitute,
              advice of any kind regarding medical assessments. For specific
              advice on any technical, financial, or medical matter, consult an
              appropriate professional.
            </p>
          </Section>

          <Section title="Limitations of Liability">
            <p>
              Q Bank Model will not be liable to you in relation to the contents
              or use of, or otherwise in connection with, this website:
            </p>
            <ul className="list-disc list-inside">
              <li>For any direct, indirect, special, or consequential loss.</li>
              <li>
                For any business losses, loss of revenue, income, profits, or
                anticipated savings, loss of contracts or business
                relationships, loss of reputation or goodwill, or loss or
                corruption of information or data.
              </li>
            </ul>
          </Section>

          <Section title="Disclaimer">
            <p>
              Q Bank Model is not affiliated with the Australian Medical Council
              or any other medical council. The questions provided are for
              practice purposes and do not reflect the actual exam content. For
              official information about the Australian Medical Council, please
              visit their official website.
            </p>
          </Section>

          <Section title="Refund Policy">
            <p>
              Q Bank Model reserves the right to refuse a refund if the user
              violates the Terms and Conditions. We follow a no-refund policy
              unless a transaction was made in error with an amount higher than
              any subscription. Except as otherwise provided, no refunds,
              cancellations, or changes to subscriptions will be allowed.
            </p>
          </Section>

          <Section title="Indemnity">
            <p>
              You agree to indemnify and hold Q Bank Model harmless from any
              losses, damages, costs, liabilities, and expenses (including legal
              expenses and any amounts paid by Q Bank Model to a third party in
              settlement of a claim or dispute) incurred or suffered by Q Bank
              Model arising from any breach by you of these terms and
              conditions.
            </p>
          </Section>

          <Section title="Breaches of These Terms and Conditions">
            <p>
              If you breach these terms and conditions in any way, Q Bank Model
              may take appropriate action to address the breach, including
              suspending your access to the website, prohibiting you from
              accessing the website, blocking computers using your IP address
              from accessing the website, contacting your internet service
              provider to request that they block your access, and/or bringing
              court proceedings against you.
            </p>
          </Section>

          <Section title="Variation">
            <p>
              Q Bank Model may revise these terms and conditions from time to
              time. Revised terms and conditions will apply from the date of
              their publication on this website. Please check this page
              regularly to ensure you are familiar with the current version.
            </p>
          </Section>

          <Section title="Entire Agreement">
            <p>
              These terms and conditions constitute the entire agreement between
              you and Q Bank Model concerning your use of this website and
              supersede all previous agreements regarding your use of this
              website.
            </p>
          </Section>

          
        </div>
      </div>
    </>
  );
};

const Section = ({ title, children }) => (
  <div className="mb-8">
    <h2 className="text-2xl font-semibold text-themeSecondryDark-0 mb-4">
      {title}
    </h2>
    <div className="text-gray-600 leading-relaxed">{children}</div>
  </div>
);

export default Terms;
