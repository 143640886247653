import React, { useEffect, useState } from "react";
import Chip from "../general/Chip";
import { t1, t2, t3, t4, t5, t6, t7 } from "../../assets";
import { Fade, Slide } from "react-awesome-reveal";
import { API } from "../../api";
import TopicsModal from "../general/Modal/TopicsModal";

const Topics = () => {
  const [categories, setCategories] = useState(null);

  const getData = async () => {
    try {
      const response = await API.getCateogriesTopics();
      setCategories(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const images = [t1, t2, t3, t4, t5, t6 , t7];

  return (
    <div className="w-full container">
      <div className="  items-start">
        <div className="heading-area flex flex-col gap-4 items-center">
          <Slide direction="left" triggerOnce>
            <Chip text={"Topics"} />
            <h2 className="  capitalize text-center">
              Key Categories We Offer for <br /> AMC CAT MCQ Exam Preparation
            </h2>
                    
          </Slide>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3     gap-6 py-8 w-full">
        {categories?.slice(0, 7).map((item, index) => (
          <Fade
            direction="bottom"
            triggerOnce
            cascade
            fraction={0.7}
            key={index}
          >
            <TopicsModal key={index} item={item} image={images[index]} />
          </Fade>
        ))}
      </div>
    </div>
  );
};

export default Topics;
