import React, { useEffect, useState } from "react";
import { logo } from "../../assets";
import { menuItems } from "../../data/header";
import { Link, useLocation } from "react-router-dom";
import LinkComponent from "../general/LinkComponent";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../store/slices/userSlice";
import { successToast } from "../../hooks/useToast";
import UserDropDown from "./UserDropDown";
import { deleteCookie } from "../../hooks/useCookies";
import DiscountBar from "./DiscountBar";
import { API } from "../../api";
import LinkComponent3 from "../general/LinkComponent3";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const user = useSelector((state) => state.user.user);
  const location = useLocation();

  const dispatch = useDispatch();
  const handleLogout = () => {
    deleteCookie("token");
    dispatch(logoutUser());
    successToast("Succesfully logout");
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // const handleAuthorized = async () => {
  //   try {
  //     const response = await API.checkAuth();
  //     console.log(response?.status);
  //   } catch (error) {
  //     handleLogout();
  //   }
  // };

  // useEffect(() => {
  //   handleAuthorized();
  // }, []);

  return (
    <div className="w-full">
      <nav
        className={` ${
          location.pathname.startsWith("/user")
            ? "bg-themeBackground-0"
            : "bg-white "
        }  text-sm  hidden lg:block        text-black   mx-auto  w-full    `}
      >
        {!location.pathname.startsWith("/user") && (
          <div className="hidden sm:block   w-full h-full">
            <DiscountBar />
          </div>
        )}
        <div className="bg-transparent container mx-auto  px-4 !py-6  border-themeGray-0  flex justify-between items-center">
          <div className="lg:hidden lg:static absolute  right-2 z-50">
            <button
              onClick={handleMenuToggle}
              className="text-white focus:outline-none"
            >
              {isMenuOpen ? (
                <svg
                  className="w-8 h-8"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="black"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="w-8 h-8"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="black"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              )}
            </button>
          </div>
          <div className="max-w-[220px] w-full">
            {location.pathname.startsWith("/user/exam") ? (
              <Link to="/user/dashboard/summary" className="cursor-pointer">
                <img
                  src={logo}
                  alt="logo"
                  width={500}
                  height={500}
                  className="max-w-[250px] w-full"
                /> 
              </Link>
            ) : (
              <Link to="/" className="cursor-pointer">
                <img
                  src={logo}
                  alt="logo"
                  width={500}
                  height={500}
                  className="max-w-[250px] w-full"
                />
              </Link>
            )}
          </div>
          <div className="flex justify-center items-center w-full ">
            {!location.pathname.startsWith("/user") && (
              <ul
                className={`custom-design lg:flex  lg:flex-row flex-col lg:h-full h-[100vh] lg:py-2 lg:pr-[5px]   ${
                  isMenuOpen ? "flex" : "hidden"
                }          lg:rounded-[67px]   pt-20 w-full lg:static  top-0 right-0 transition-all  text-black md:text-black bg-themeGray-0 lg:bg-webGray-0 max-w-[580px] `}
              >
                {menuItems?.map((item, index) => (
                  <li
                    key={index}
                    className=" w-full font-semibold whitespace-nowrap text-center"
                  >
                    {item?.to === "/user/exam" ? (
                      <Link
                        to={"/user/exam"}
                        className={`cursor-pointer    hover:text-themeButton-0  py-1 px-3 w-full table ${
                          location.pathname === item.to
                            ? "text-themeButton-0  "
                            : ""
                        }`}
                      >
                        {user?.freeTrial
                          ? "Free Test Drive"
                          : user?.authorized
                          ? "Attempt Exam"
                          : "Free Test Drive"}
                      </Link>
                    ) : (
                      <Link
                        to={item.to}
                        className={`cursor-pointer    hover:text-themeButton-0  py-1 px-3 w-full table ${
                          location.pathname === item.to
                            ? "text-themeButton-0  "
                            : ""
                        }`}
                      >
                        {item.label}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
          {!user?.authorized && (
            <div className="hidden justify-center  gap-2 items-center text-[12px]  lg:flex   cursor-pointer">
              <LinkComponent3
                border={true}
                varient2={true}
                to={"/sign-in"}
                text={"Login"}
              />
              <LinkComponent3 to={"/sign-up"} text={"Get Started"} />
            </div>
          )}

          {user?.authorized && (
            <div className="mr-12 sm:mr-0 max-w-[250px] w-full     flex justify-end items-center cursor-pointer">
              {location.pathname.startsWith("/user") && (
                <div className="mr-auto">
                  <LinkComponent3
                    text={"Back To Dashboard"}
                    to={"user/dashboard/summary"}
                  />
                </div>
              )}
              <UserDropDown handleLogout={handleLogout} user={user} />
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Header;
