import React from "react";

const Statistics = ({ statistics }) => {
  return (
    <div className="w-full bg-transparent rounded-lg  ">
       
      <div className="profile-header  py-4   interFont  my-5 ">
        <h2 className=" text-2xl interFont capitalize font-bold ">
          Attempted Questions Stats
        </h2>
      </div>

      <div className="grid grid-cols-4 py-4 text-white font-semibold bg-themeButton-0 rounded-t-lg">
        <p className="text-start px-4">Subject</p>
        <p className="text-start px-4">Percentage</p>
        <p className="text-start px-4">Correct</p>
        <p className="text-start px-4">Incorrect</p>
      </div>

      {statistics?.map((statistic, index) => (
        <div
          key={index}
          className={`grid grid-cols-4 py-3 text-sm ${
            index % 2 === 0 ? "bg-white text-black" : "bg-white text-black"
          }`}
        >
          <p className="text-start px-4">{statistic?.Subjects}</p>
          <p className="text-start px-4">{statistic?.Percentage}</p>
          <p className="text-start px-4">{statistic?.Correct}</p>
          <p className="text-start px-4">{statistic?.Incorrect}</p>
        </div>
      ))}
    </div>
  );
};

export default Statistics;
