import React, { useState } from "react";
import PricingCart from "./PricingCart";
import { API } from "../../api";
import LinkComponent3 from "../general/LinkComponent3";

const PricingComponent = ({
  data,
  authorized,
  activeSubsription,
  hide = false,
}) => {
  const discounted = [
    {
      id: 1,
      amount: 19.99,
    },
    {
      id: 2,
      amount: 29.99,
    },
    {
      id: 3,
      amount: 39.99,
    },
  ];
  return (
    <div className="PricingComponent ">
      <div className={`${!hide && `container`} w-full mx-auto`}>
        {hide ? (
          <div className="profile-header  py-4   interFont flex items-center w-full justify-between  ">
            <h2 className=" text-2xl interFont capitalize font-bold ">
              All Subscriptions
            </h2>
            <LinkComponent3
              to={"/user/dashboard/subscription"}
              text={"My Subscription"}
            />
          </div>
        ) : (
          <div className="lg:px-0 lg:py-0  mb-6 lg:mb-10">
            <h1 className="text-themeSecondry-0 text-3xl sm:text-4xl lg:text-5xl capitalize   text-center">
              Subscription plans
            </h1>
            <p className="flex justify-center interfont text-[16px] py-3 px-3 text-black text-center">
              Choose the best suitable plan for your AMC CAT practice{" "}
            </p>
          </div>
        )}
        {/* {!hide && (
          <div className="lg:px-0 lg:py-0  mb-6 lg:mb-6">
            <h1 className="text-themeSecondry-0 text-3xl sm:text-4xl lg:text-5xl capitalize   text-center">
              Subscription plans
            </h1>
            <p className="flex justify-center interfont text-[16px] py-3 px-3 text-black text-center">
              Choose the best suitable plan for your AMC CAT practice{" "}
            </p>
          </div>
        )} */}
        <div
          className={` grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ${
            hide ? `w-full` : `w-fit`
          }     mx-auto gap-20 md:gap-8  pt-4 `}
        >
          {data?.map((item, index) => (
            <PricingCart
              recommended={index === 1 ? true : false}
              authorized={authorized}
              key={index}
              plan={item?.name}
              duration={item?.duration}
              durationMode={item?.durationMode}
              price={item?.price}
              id={item?.id}
              item={item}
              activeSubsription={activeSubsription}
              discounted={discounted?.find((data) => data?.id === item?.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingComponent;
