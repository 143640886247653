import React, { useEffect } from 'react'
import LogIn from '../../components/user/login/LogIn'

const LoginpPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
   <LogIn/>
  )
}

export default LoginpPage