import React from "react";
import Chip from "../general/Chip";
import LinkComponent3 from "../general/LinkComponent3";

const AboutHome = ({ imageSrc, heading, paragh, reverse = false }) => {
  return (
    <div className="HomeAbout">
      <div className="container w-full mx-auto">
        <div
          className={`flex ${
            reverse ? "flex-row-reverse" : ""
          } justify-start flex-wrap lg:flex-nowrap items-center px-0 py-0 lg:px-0 lg:py-0 gap-7 lg:gap-10`}
        >
          <div className="rounded-[20px] bg-transparent h-fit w-full">
            <img
              width={1000}
              height={1000}
              src={imageSrc}
              alt="About"
              className="w-full rounded-[8px] object-fit"
            />
          </div>
          <div className="w-full flex flex-col items-center lg:items-start gap-5">
            <Chip text={"Smart Dashboard"} />
            <h2 className="text-center lg:text-start noUppercase">{heading}</h2>
            <p className="text-center lg:text-start">{paragh}</p>
            <LinkComponent3 to={"subscriptions"} text={"Buy Now"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHome;
