import React, { useEffect, useState } from "react";
import InputField from "../../general/InputField";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { signUpSchema } from "../../../validations/signup";
import { API } from "../../../api";
import ButtonComponent from "../../general/ButtonComponent";
import { errorToast, successToast } from "../../../hooks/useToast";
import { allcountry } from "../../../data/country";
import { setCookie } from "../../../hooks/useCookies";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useQuery } from "../../../hooks/queryParam";
import MetaData from "../../seo/MetaData";

const SignUp = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(signUpSchema) });
  const [country, setCountry] = useState("");
  const [signUpError, setSignUpError] = useState("");

  const params = useQuery();
  const relocate = params.get("relocate");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const [firstName, lastName] = data?.fullName?.split(" ");
      delete data.termconditions;
      delete data.fullName;

      const response = await API.registerUser({
        ...data,
        firstName,
        lastName : lastName ? lastName : "  ",
        country: country,
      });
      setCookie("token", response?.data?.data?.token);

      successToast(response?.data?.message);
      setLoading(false);

      if (relocate) {
        navigate("/user/verify-otp?relocate=true");
      } else {
        navigate("/user/verify-otp");
      }
    } catch (error) {
      setLoading(false);
      // errorToast(error, "Can not SignUp In at the moment");
      setSignUpError(error?.response?.data?.message);
    }
  };

  const getCountry = async () => {
    try {
      const response = await axios.get("https://api.country.is");
      const code = response?.data?.country;

      if (code) {
        const countryResponse = await axios.get(
          `https://restcountries.com/v3.1/alpha/${code}`
        );
        const countryName = countryResponse?.data[0]?.name?.common;
        setCountry(countryName);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCountry();
  }, []);

  return (
    <div className="SignUp ">
      <MetaData
        title={"Qbank Model Sign Up - Start AMC MCQ Exam Prep"}
        description={
          "Join Qbank Model today and begin your journey to AMC MCQ exam success. Sign up for access to top-notch study materials and practice questions."
        }
        keywords={
          "Qbank Model sign up, AMC MCQ exam registration, join Qbank Model, start exam preparation"
        }
      />

      <div className="container w-full  mx-auto">
        <div className="py-6 flex justify-center lg:py-16 gap-7 lg:gap-10">
          <div className="w-full lg:max-w-[550px]  ">
            <h2 className="text-3xl md:text-5xl     font-medium text-center">
              Sign Up
              {signUpError && (
                <div className="p-4 my-4 bg-red-50 border border-red-300 rounded-lg  ">
                  <p className="text-center font-medium text-[14px] text-red-700">
                    {signUpError}
                  </p>
                </div>
              )}
            </h2>

            <form
              className="grid grid-col-1 gap-2"
              onSubmit={handleSubmit(onSubmit)}
            >
              <InputField
                label="Full name"
                type="text"
                placeholder="Enter Name"
                errors={errors}
                name="fullName"
                register={register}
              />

              <InputField
                label="Email Address"
                type="text"
                placeholder="Email"
                errors={errors}
                name="email"
                register={register}
              />
              <InputField
                label="Password"
                type="password"
                placeholder="Enter Password"
                errors={errors}
                name="password"
                register={register}
              />
              {/* <InputField
                label="country"
                type="select"
                options={allcountry}
                placeholder="Select your Country"
                errors={errors}
                name="country"
                register={register}
              /> */}
              <InputField
                type="Checkbox"
                placeholder="I agree with the terms and conditions"
                errors={errors}
                name="termconditions"
                register={register}
              />

              <div className="w-full  mt-8">
                <ButtonComponent
                  type={"submit"}
                  text="Register"
                  loading={loading}
                  isActive={true}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
