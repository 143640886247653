import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import BlogSlug from "../../components/blog/BlogSlug";

const BlogSlugPage = () => {
  const { slug } = useParams();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <BlogSlug slug={slug} />
    </>
  );
};

export default BlogSlugPage;
