import React, { useEffect } from "react";
import SignUp from "../../components/user/signup/SignUp";

const SignUpPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return <SignUp />;
};

export default SignUpPage;
