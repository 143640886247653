import React, { useState } from "react";
import { Tabs, Tab, Button } from "@nextui-org/react";
import ChooseCategories from "./ChooseCategories";
import { useForm } from "react-hook-form";
import ButtonComponent from "../general/ButtonComponent";
import ExamModes from "./ExamModes";
import { yupResolver } from "@hookform/resolvers/yup";
import { examModeSchema } from "../../validations/examMode";
import { errorToast, successToast } from "../../hooks/useToast";
import { API } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { createUserExam } from "../../store/slices/userExam";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CreateExamModal from "../general/Modal/CreateExamModal";

const CreateExamTab = ({ categories, freeTrial = false , attempts }) => {
  const [selected, setSelected] = useState("subjects");
  const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({ resolver: yupResolver(examModeSchema) });

  const [errorModal, setErrorModal] = useState(false);
  const [errorResponse, setError] = useState(false);

  const onSubmit = async (data) => {
    const selectedCategoryIds = Object.keys(data.categories)
      .filter((key) => data.categories[key])
      .map(Number);

    const payload = {
      categoriesId: selectedCategoryIds,
      typeOfQuestions: data?.typeOfQuestions,
      examMode: data?.examMode,
      questionsPerExam: Number(data?.questionsPerExam),
      difficultyMode: data?.difficultyMode,
    };
    setLoading(true);
    try {
      const response = await API.createUserExam(payload);
      if (response?.data?.data?.examQuestions?.length > 0) {
        dispatch(createUserExam(response?.data?.data));
        successToast(response);
        setLoading(false);
        navigate("/user/attempt/exam");
      } else {
        setLoading(false);

        toast.error(
          "The options you selected, does not have any questions assigned yet"
        );
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      // errorToast(error, "Can not create exam");
      setError(error?.response?.data?.message);
      setErrorModal(true);
    }
  };

  

  const watchedCategories = watch("categories", {});
  const areCategoriesSelected = Object.values(watchedCategories).some(Boolean);
  // const examData = useSelector((state) => state.exam.examData);
  return (
    <div className="flex w-full    flex-col py-8 ">
      <CreateExamModal
        error={errorResponse}
        open={errorModal}
        setErrorModal={setErrorModal}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Tabs
          aria-label="Options"
          selectedKey={selected}
          onSelectionChange={setSelected}
          fullWidth
          disabledKeys={["create"]}
          classNames={{
            tabList: "flex justify-evenly items-center py-0",
            cursor: "w-full bg-themeButton-0",
            tab: "px-2 h-[55px] group-data-[selected=true]:bg-themePrimary-0 rounded-[8px]",
            tabContent: "group-data-[selected=true]:text-white font-semibold",
          }}
        >
          <Tab key="create" title="Create an Exam">
            {null}
          </Tab>
          <Tab key="subjects" title="Choose Subjects">
            {categories && (
              <ChooseCategories
                categories={categories}
                register={register}
                errors={errors}
                setValue={setValue}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                freeTrial={freeTrial}
              />
            )}
          </Tab>
          <Tab key="choose" title="Choose Type">
            <ExamModes register={register} errors={errors} freeTrial={freeTrial} attempts={attempts} />
          </Tab>
        </Tabs>

        <div className="flex justify-end items-center gap-5  ">
          <Button
            isDisabled={!areCategoriesSelected}
            onClick={() =>
              setSelected(selected === "choose" ? "subjects" : "choose")
            }
            className={`text-white hover:bg-themeButtonHover-0    text-[13px] px-6 !py-[10px] !rounded-[8px]  font-semibold border bg-themeButton-0  `}
          >
            {selected === "choose" ? "Previous" : "Next"}
          </Button>

          {selected === "choose" && (
            <Button
              isLoading={loading}
              type="submit"
              className={`text-white hover:bg-themeButtonHover-0 !opacity-100 text-[13px] px-6 !py-[10px] !rounded-[8px]  font-semibold border bg-themeButton-0  `}
            >
              Create Exam
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default CreateExamTab;
