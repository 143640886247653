import React from "react";
import {
  Modal,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";

const TopicsModal = ({ item, image }) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <>
      <div
        onClick={onOpen}
        className="feature-card flex gap-3 py-6 px-3 items-center group w-full border-b-2 border-gray-200 transition-all duration-300 hover:bg-gray-50 cursor-pointer"
      >
        <div className="icon-wrapper w-16 h-16 rounded-full bg-white shadow-md flex justify-center items-center overflow-hidden transition-all duration-300 hover:scale-110">
          <img
            src={image}
            alt={item?.name}
            className="w-8 h-8 object-contain transition-all duration-300 hover:rotate-3"
          />
        </div>
        <p className="text-center  ">{item?.categoryName}</p>
      </div>

      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        scrollBehavior="inside"
        className="max-w-[800px] mx-auto"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col justify-center items-center">
                <h2 className=" font-bold text-themeSecondry-0 text-center mt-2  ">
                  {item?.categoryName}
                </h2>
                <p className="mt-1 text-center">
                  Below are the topics we covered in above category questions.
                </p>
              </ModalHeader>

              <ModalBody className="px-6 ">
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                  {item?.topics?.map((data, index) =>
                    data?.name !== "Default" ? (
                      <div
                        key={index}
                        className="bg-themeBackground-0 border-2 border-themePrimary-0 p-4 rounded-lg flex justify-center items-center transition-all duration-300 cursor-text"
                      >
                        <p className="text-center text-themeSecondryDark-0">
                          {data?.name}
                        </p>
                      </div>
                    ) : null
                  )}
                </div>
              </ModalBody>

              <ModalFooter className=" flex justify-center md:justify-end">
                <Button
                  auto
                  flat
                  className={"bg-themeButton-0  text-white"}
                  onPress={onClose}
                >
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default TopicsModal;
