import React, { useEffect, useState } from "react";
import { API } from "../../api";
import { errorToast } from "../../hooks/useToast";
import Loader from "../../components/general/Loader";
import PieChart from "../../components/exam/result/PieChart";
import Statistics from "../../components/exam/result/Statistics";
import NoContentAvailable from "../../components/general/NoContentAvailable";
import { examIcon } from "../../assets";
import MetaData from "../../components/seo/MetaData";
import ProgressDashboard from "../../components/user/ProgressDashboard";

const MySummaryPage = () => {
  const [loading, setLoading] = useState(true);
  const [graphData, setGraphData] = useState(null);
  const [statistics, getStatistics] = useState(null);

  const getSummery = async () => {
    let response;
    try {
      response = await API.getCorrectWrong();
      setGraphData(response?.data?.data[0]);
      setLoading(false);

      response = await API.getUserDashboardStatistics();
      getStatistics(response?.data?.data);
    } catch (error) {
      setLoading(false);
      errorToast(error, "Can not fetch data");
    }
  };

  useEffect(() => {
    getSummery();
  }, []);

  return (
    <div className="summery flex gap-4 flex-col">
      <MetaData
        title={"Summary Dashboard - Qbank Model"}
        description={
          "View your exam summary with Qbank Model. Monitor your performance, track achievements, and analyze results."
        }
        keywords={
          " exam summary, Qbank Model results, performance tracking, exam achievements"
        }
      />

      <div className="profile-header  py-4   interFont  ">
        <h2 className=" text-2xl interFont capitalize font-bold ">
          Your Progress{" "}
        </h2>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            {graphData && statistics?.length > 0 ? (
              <div className="">
                <div className="flex flex-col md:flex-row items-stretch justify-between gap-6">
                  <ProgressDashboard />
                  <PieChart graphData={graphData} />
                </div>
                <div className="">
                  <Statistics statistics={statistics} />
                </div>
              </div>
            ) : (
              <NoContentAvailable
                icon={examIcon}
                noContentText={"Create Your First Exam"}
                button={true}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MySummaryPage;
