import React, { useState } from "react";
import Chip from "../general/Chip";
import { s1, s2, s3, s4, s5, s6 } from "../../assets";
import { Bounce, Fade } from "react-awesome-reveal";

const KeyFeatures = () => {
  const data = [
    {
      name: (
        <>
          Time/Explanation <br /> Based
        </>
      ),
      p: "Track your progress with timed exams or detailed explanations for each question.",
      icon: s1,
    },
    {
      name: (
        <>
          Detailed <br /> Explanation
        </>
      ),
      p: "Understand each answer thoroughly with detailed explanations for both correct and incorrect options.",
      icon: s2,
    },
    {
      name: (
        <>
          Multiple <br /> Difficulty Level
        </>
      ),
      p: "Practice with questions at various difficulty levels to simulate real exam conditions.",
      icon: s3,
    },
    {
      name: (
        <>
          Pause/Resume <br /> Exam
        </>
      ),
      p: "Flexibility to pause and resume exams anytime at your convenience.",
      icon: s4,
    },
    {
      name: (
        <>
          Analytics <br /> and Reporting
        </>
      ),
      p: "Gain insights into your performance with detailed analytics and progress reports.",
      icon: s5,
    },
    {
      name: (
        <>
          Worldwide <br /> Access
        </>
      ),
      p: "Access the platform from anywhere around the globe with internet connectivity.",
      icon: s6,
    },
  ];

  const [show, setShow] = useState(null);

  const toggleShow = (index) => {
    setShow(show === index ? null : index);
  };

  return (
    <div className="w-full flex flex-col items-center gap-5 container">
      <Fade direction="bottom" triggerOnce>
        <Chip text={"Key Features"} />

        <h2 className="text-center w-full px-3 md:max-w-[1000px] noUppercase">
          Ease in AMC CAT MCQ Examination Preparation <br />
          Precision, Practice, Performance
        </h2>

        <p className="text-center w-full px-3 md:max-w-[1000px]">
          Prepare for the AMC CAT exam with our comprehensive features designed
          to ensure your success. Experience precise and targeted practice
          questions, detailed performance analytics, and expert guidance to help
          you excel.
        </p>
      </Fade>

      <div className="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-6 gap-10 py-8">
        <Bounce direction="bottom" cascade triggerOnce>
          {data?.map((item, index) => (
            <div
              className="feature-card flex flex-col gap-3 py-6 px-3 items-center group hover:bg-themeButton-0/10 w-[200px] rounded-[15px] bg-white shadow-md hover:shadow-transparent transition-all duration-300 hover:shadow-2xl hover:-translate-y-2 cursor-text"
              key={index}
            >
              <div 
                className="icon-wrapper w-16 h-16 rounded-full bg-white shadow-md flex justify-center items-center overflow-hidden transition-all duration-300 hover:scale-110 cursor-pointer"
                onClick={() => toggleShow(index)}
              >
                <img
                  src={item?.icon}
                  alt={item?.name}
                  className="w-[30px] transition-all duration-300 hover:rotate-12"
                />
              </div>
              <p className="text-center text-[#0C2452] font-medium transition-all duration-300 group-hover:text-themeButton-0">
                {item?.name}
              </p>
              <div
                className={`transition-all duration-300 overflow-hidden ${
                  show === index ? "max-h-[100px] opacity-100" : "max-h-0 opacity-0"
                }`}
              >
                <p className="text-center text-sm">{item?.p}</p>
              </div>
            </div>
          ))}
        </Bounce>
      </div>
    </div>
  );
};

export default KeyFeatures;