import React, { useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import LinkComponent2 from "../LinkComponent2";

const ContinueExamModal = ({ error, open = false, setErrorModal }) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  useEffect(() => {
    if (open) {
      onOpen();
    }
    return () => {
      setErrorModal(false);
    };
  }, [open]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        className="bg-white max-w-[500px] w-full"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1"></ModalHeader>
              <ModalBody>
                <p className="text-themeButtonHover-0 text-center">{error}</p>
              </ModalBody>
              <ModalFooter className="flex justify-center">
                <LinkComponent2 text={"Buy Now"} to={"/user/dashboard/all-subscriptions"} />
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ContinueExamModal;
