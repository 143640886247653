import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import LinkComponent from "../../components/general/LinkComponent";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../store/slices/userSlice";
import MetaData from "../../components/seo/MetaData";

const UnSuccesfullPage = () => {
  

  return (
    <div className="flex h-[60vh] w-full flex-col items-center justify-center gap-6 bg-gray-100 px-4 dark:bg-gray-900">
      <MetaData
        title={"Payment Unsuccessful | Qbank Model"}
        description={
          "Your payment was unsuccessful. Please try again or contact support for assistance with your Qbank Model transaction"
        }
        keywords={
          "payment issue, Qbank Model transaction, payment failed, contact support"
        }
      />

      <div className="space-y-2 text-center">
        <h1>Ops!</h1>
        <p className="text-lg text-gray-500 dark:text-gray-400">
          Due to some errors. Payment was unsuccesfull
        </p>
      </div>

      <LinkComponent to={"/subscriptions"} text={"Go to pricing"} />
    </div>
  );
};

export default UnSuccesfullPage;
