import * as yup from "yup";
const examModeSchema = yup.object().shape({
  typeOfQuestions: yup.string().required("Please Select type of Questions"),
  examMode: yup.string().required("Please Select mode of your Exam"),
  questionsPerExam: yup
    .string()
    .required("Please select how much questions you want to attempt"),
  difficultyMode: yup
    .string()
    .required("Please Select difficulty level of your exam"),
});

export { examModeSchema };
