import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { errorToast } from "../../hooks/useToast";

const ProtectedRoutes = ({ children, signUp = false }) => {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.authorized) {
      navigate(signUp ? "/sign-up" : "/sign-in");
    } else if (!user?.otpVerified) {
      navigate("/user/verify-otp");
    } else if (user?.firstName === "N/A") {
      errorToast(
        null,
        "Please Complete your profile first to gain full access"
      );
      navigate("/user/setup-profile");
    }
  }, [user, navigate, signUp]);

  return user?.authorized && user?.otpVerified ? children : null;
};

export default ProtectedRoutes;
