import React, { useEffect, useState } from "react";
import CreateExamTab from "../../components/exam/CreateExamTab";
import { errorToast } from "../../hooks/useToast";
import { API } from "../../api";
import { useSelector } from "react-redux";
import MetaData from "../../components/seo/MetaData";

const CreateExamForm = () => {
  const [categories, setCategories] = useState(false);

  const freeTrial = useSelector((state) => state.user.user.freeTrial);
 const [attempts , setAttempts] = useState(false)

  const getData = async () => {
    try {
      const response = await API.getAllCategories();
      const isLive = response?.data?.data;

      setCategories(isLive?.filter((item) => item?.isLive));

      const response1 = await API.attemptsLeft();
      setAttempts(response1?.data?.data)
    } catch (error) {
      console.log(error);
      errorToast(error, "Can not fetch subjects");
    }
  };

  useEffect(() => {
    getData();
  }, []);

   return (
    <div className="max-w-[530px] w-full mx-auto px-3  ">
      <MetaData
        title={"AMC MCQ Exam Practice Questions - Qbank Model"}
        description={
          "Practice with top AMC MCQ exam questions. Enhance your medical knowledge and boost your exam performance with Qbank Model's comprehensive practice tests."
        }
        keywords={
          "AMC MCQ exam practice, medical questions, exam preparation, Qbank Model practice tests"
        }
      />
      <CreateExamTab categories={categories} freeTrial={freeTrial} attempts={attempts} />
    </div>
  );
};

export default CreateExamForm;
