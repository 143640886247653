import React, { useEffect } from "react";
import InnerHeader from "../../components/general/InnerHeader";
import MetaData from "../../components/seo/MetaData";

const Policy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <div className="home w-full pagelayout">
        <MetaData
          title={"Privacy Policy Qbankmodel - Your AMC Exam Partner"}
          description={
            "Learn about Qbankmodel's privacy policy. We are committed to protecting your personal information and ensuring a secure experience on our platform."
          }
          keywords={
            "privacy policy, Qbankmodel, AMC exam, data protection, personal information"
          }
        />

        <InnerHeader text={"Privacy Policy"} />

        <div className="max-w-[1200px] mx-auto w-full px-3  ">
          <p className="text-gray-600 mb-6 leading-relaxed">
            We value your privacy and are committed to protecting your personal
            information. Please read this policy to understand how we handle
            your Personally Identifiable Information (PII) on our website.
          </p>

          <Section title="What Information Do We Collect?">
            <p>
              When you visit our site, register, or place an order, we may ask
              for details such as your name, email address, and phone number to
              enhance your experience.
            </p>
          </Section>

          <Section title="When Do We Collect Information?">
            <p>
              We collect information when you register, place an order, fill out
              forms, use our live chat, open support tickets, provide feedback,
              or enter information on our site.
            </p>
          </Section>

          <Section title="How Do We Use Your Information?">
            <ul className="list-disc list-inside">
              <li>
                Personalizing your experience and delivering relevant content
                and product offerings.
              </li>
              <li>Improving our website to better serve you.</li>
              <li>Responding efficiently to your customer service requests.</li>
              <li>Processing your transactions quickly.</li>
              <li>
                Sending updates and information regarding your orders, products,
                and services.
              </li>
              <li>
                Following up after correspondence (live chat, email, or phone
                inquiries).
              </li>
            </ul>
          </Section>

          <Section title="How Do We Protect Your Information?">
            <p>
              We only provide preparation question data and do not request
              credit card numbers. We utilize regular malware scanning and
              secure your personal information behind protected networks. Access
              is limited to authorized personnel who are required to maintain
              confidentiality. All sensitive information is encrypted via Secure
              Socket Layer (SSL) technology. We implement security measures when
              you place an order or access your information to ensure your
              data's safety. All transactions are processed through a gateway
              provider and are not stored on our servers.
            </p>
          </Section>

          <Section title="Do We Use Cookies?">
            <p>
              We do not use cookies for tracking purposes. You can choose to
              have your computer warn you each time a cookie is being sent, or
              you can turn off all cookies through your browser settings.
              Disabling cookies might affect your site experience but you will
              still be able to place orders.
            </p>
          </Section>

          <Section title="Third-party Disclosure">
            <p>
              We do not sell, trade, or transfer your Personally Identifiable
              Information to outside parties. We do not represent the Australian
              Medical Council; we only provide exam preparation services.
            </p>
          </Section>

          <Section title="Third-party Links">
            <p>
              We do not include or offer third-party products or services on our
              website.
            </p>
          </Section>

          <Section title="Google">
            <p>
              We adhere to Google's advertising principles to ensure a positive
              user experience. We have not enabled Google AdSense but may do so
              in the future.
            </p>
          </Section>

          <Section title="Do Not Track Signals">
            <p>
              We honor Do Not Track signals and refrain from tracking, placing
              cookies, or using advertising when a Do Not Track (DNT) browser
              mechanism is in place.
            </p>
          </Section>

          <Section title="Third-party Behavioral Tracking">
            <p>
              We allow third-party behavioral tracking for a better user
              experience.
            </p>
          </Section>

          <Section title="Fair Information Practices">
            <p>
              We adhere to the Fair Information Practices Principles, which
              guide data protection laws globally. If a data breach occurs, we
              will notify you via email within 7 business days and via in-site
              notification within 1 business day. We also comply with the
              Individual Redress Principle, ensuring that individuals have
              enforceable rights against data collectors and processors.
            </p>
          </Section>

          <Section title="CAN-SPAM Act">
            <p>
              The CAN-SPAM Act sets rules for commercial email, giving
              recipients the right to have emails stopped. We collect your email
              to:
            </p>
            <ul className="list-disc list-inside">
              <li>
                Send information, respond to inquiries, and other requests or
                questions.
              </li>
              <li>Process orders and send updates related to orders.</li>
              <li>
                Send additional information about our products and services.
              </li>
            </ul>
            <p className="mt-4">In compliance with CAN-SPAM, we:</p>
            <ul className="list-disc list-inside">
              <li>
                Do not use false or misleading subjects or email addresses.
              </li>
              <li>Identify messages as advertisements reasonably.</li>
              <li>
                Include our business or site headquarters' physical address.
              </li>
              <li>
                Monitor third-party email marketing services for compliance.
              </li>
              <li>Honor opt-out/unsubscribe requests promptly.</li>
              <li>
                Allow users to unsubscribe via a link at the bottom of each
                email.
              </li>
            </ul>
          </Section>

          <div className="bg-themeSecondryDark-0 rounded-lg p-6 my-8">
            <h2 className="text-xl font-semibold text-white mb-2">
              Contacting Us
            </h2>
            <p className="text-white">
              For any questions regarding this privacy policy, please contact us
              at:
              <br />
              <a
                href="mailto:support@qbankmodel.com"
                className="text-indigo-300 hover:text-indigo-100"
              >
                support@qbankmodel.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const Section = ({ title, children }) => (
  <div className="mb-8">
    <h2 className="text-2xl font-semibold text-themeSecondryDark-0 mb-4">
      {title}
    </h2>
    <div className="text-gray-600 leading-relaxed">{children}</div>
  </div>
);

export default Policy;
