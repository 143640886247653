import { Textarea } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import InputField from "../../components/general/InputField";
import ButtonComponent from "../../components/general/ButtonComponent";
import { Controller, useForm } from "react-hook-form";
import { PhoneInput, getActiveFormattingMask } from "react-international-phone";
import "react-international-phone/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactSchema } from "../../validations/contactUs";
import { errorToast, successToast } from "../../hooks/useToast";
import { API } from "../../api";
import axios from "axios";
import { Helmet } from "react-helmet";
import MetaData from "../../components/seo/MetaData";
const ContactPage = () => {
  const {
    register,
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactSchema),
  });

  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("");

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await API.sendContactQuery(data);
      successToast(
        "We have received your query! Someone will get in touch with you soon."
      );
      reset();
      setValue("name", "");
      setValue("email", "");
      setValue("subject", "");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorToast(error, "Can not submit your query! Try again letter");
      console.log(error);
    }
  };

  const getCountry = async () => {
    try {
      const response = await axios.get("https://api.country.is");
      const code = response?.data?.country;
      setCountry(String(code?.toLowerCase()));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCountry();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className="w-full py-12  ">
      <MetaData
        title={"Contact Qbank Model - AMC MCQ Exam Prep Support"}
        description={
          " Get in touch with Qbank Model for any queries or support related to your AMC MCQ exam preparation. We're here to help you succeed."
        }
        keywords={
          "contact Qbank Model, AMC MCQ exam support, customer service, exam preparation help"
        }
      />
      <div className="container max-w-4xl space-y-8 px-4 md:px-6">
        <div className="space-y-4 text-center">
          <h1 className="">Contact Us</h1>
          <p className=" ">
            Have a question or need help? Fill out the form below and we'll get
            back to you as soon as possible.
          </p>
        </div>
        <form
          className="w-full max-w-[900px] mx-auto"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <InputField
              label="Name"
              type="text"
              placeholder="Your name"
              errors={errors}
              name="name"
              register={register}
            />
            <InputField
              label="Email"
              type="email"
              placeholder="Your email"
              errors={errors}
              name="email"
              register={register}
            />
          </div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 py-3">
            <div>
              <label className="text-[#8F8F8F] text-sm font-medium  px-1 capitalize">
                Phone Number
              </label>
              {country && (
                <div className="w-full mt-2">
                  <Controller
                    control={control}
                    name="phone"
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        value={value}
                        onChange={onChange}
                        defaultCountry={country}
                        placeholder="Enter phone number"
                      />
                    )}
                  />
                  {errors && errors?.phone && (
                    <p className="text-tiny text-danger pl-3 mt-1">
                      {errors?.phone?.message}
                    </p>
                  )}
                </div>
              )}
            </div>

            <InputField
              label="Subject"
              type="text"
              placeholder="Subject"
              errors={errors}
              name="subject"
              register={register}
            />
          </div>
          <div className="h-full">
            <label className="text-[#8F8F8F] text-sm font-medium  px-1 capitalize">
              Message
            </label>

            <div className="mt-2 h-full">
              <Textarea
                placeholder="Enter your message"
                className="w-full"
                variant="bordered"
                minRows={7}
                classNames={{
                  inputWrapper: [
                    "bordered",
                    "border-1",
                    "border-[#EBEBEB]",
                    "hover:border-[#3F7FAE]",
                    "custom-input-design",
                  ],
                }}
                {...register("message")}
              />

              {errors && errors?.message && (
                <p className="text-tiny text-danger pl-3 mt-1">
                  {errors?.message?.message}
                </p>
              )}
            </div>
          </div>

          <div className="py-3">
            <ButtonComponent
              loading={loading}
              type={"submit"}
              text="Submit"
              isActive={true}
            />
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactPage;
