import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import { FaCircleInfo } from "react-icons/fa6";
import { labValues } from "../../../data/labValues";

const LaboratoryValues = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <>
      <Button
        onPress={onOpen}
        startContent={<FaCircleInfo />}
        className="bg-themeSecondry-0 md:bg-themeButton-0 text-white hover:bg-themeButtonHover-0 !opacity-100 text-[13px] px-6 !py-[10px] !rounded-[8px]   w-full md:w-fit font-semibold"
      >
        Laboratory Values
      </Button>{" "}
      <Modal
        size="2xl"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        scrollBehavior={"inside"}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Lab Values
              </ModalHeader>
              <ModalBody>
                {labValues?.map((category, index) => (
                  <div key={index}>
                    <h3>{category?.category}</h3>
                    <ul>
                      {category?.values?.map((value, valueIndex) => (
                        <li key={valueIndex}>
                          <div className=" ">{value}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default LaboratoryValues;
