import React from "react";

const ResultProgressBar = ({ value }) => {
  const width = Math.max(0, Math.min(100, value));

  return (
    <div className="bg-white rounded-[8px] w-full p-1">
      <div
        className="bg-themeSecondry-0   rounded-[8px] py-1"
        style={{ width: `${width}%`, transition: "width 3s ease-in-out" }}
      >
        <p
          className={`text-sm font-semibold text-right px-1 ${
            value > 0 ? "text-white" : "text-black"
          }  `}
        >
          {value}%
        </p>
      </div>
    </div>
  );
};

export default ResultProgressBar;
