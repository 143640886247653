import { Button } from "@nextui-org/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "../../general/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgetPasswordSchema } from "../../../validations/account";
import { errorToast, successToast } from "../../../hooks/useToast";
import { API } from "../../../api";
import ButtonComponent from "../../general/ButtonComponent";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgetPasswordSchema),
  });

  const [message, setMessage] = useState("");
  const onSubmit = async (formData) => {
    setLoading(true);

    console.log(formData , "data")
    try {
      const response = await API.forgetPassword(formData);
      reset(); // Reset form after successful submission
      // successToast(response?.data?.message);
      setMessage(response?.data?.message);
    } catch (error) {
      errorToast(error, "Couldn't send email, try again later");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgotpassword-page w-full">
      <div className="container">
        <div className="container mx-auto py-20 px-2.5 sm:px-0 h-[60vh] w-full flex justify-center items-center">
          <div className="w-[360px] max-w-[750px] mx-auto">
            <div className="form-area">
              <h1 className="text-center text-3xl sm:text-4xl font-normal mb-7">
                Forgot Password
              </h1>
              <p className="text-center text-md">
                Enter your email address and we will send you a link to reset
                your password.
              </p>
              <form
                className="grid grid-col-1 gap-6 my-4 "
                onSubmit={handleSubmit(onSubmit)}
              >
                <InputField
                  label="Your Email"
                  type="email"
                  placeholder="Enter Your Email Address"
                  errors={errors}
                  name="email"
                  register={register}
                />

                {message && (
                  <div className="w-full bg-themePrimary-0 px-2 py-3 rounded-lg ">
                    <p className="text-center text-white ">{message}</p>
                  </div>
                )}
                <div className="w-full ">
                  <ButtonComponent
                    loading={loading}
                    text={"Reset"}
                    type={"submit"}
                    isActive={true}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
