import React from "react";
import { Link } from "react-router-dom";

const LinkComponent3 = ({
  to,
  text,
  opsite = true,
  border = false,
  blue = false,
}) => {
  return (
    <Link
      to={to}
      className={`w-fit rounded-full font-bold interFont  ${
        opsite
          ? `bg-themeButton-0 hover:bg-themeButtonHover-0 border-themeButton-0 hover:border-themeButtonHover-0`
          : `bg-themeSecondry-0 hover:bg-themeButtonHover-0 border-themeSecondry-0 hover:border-themeButtonHover-0`
      }   !opacity-100   text-[14px] transition-all   whitespace-nowrap    border-[2px]  px-6 !py-[10px]
       ${blue ? "bg-themeSecondryDark-0 border-none !py-[12px]" : "bg-themeButton-0"}
      ${
        border
          ? "border-themeButton-0 bg-transparent text-themeButton-0 hover:text-white text-[14px] transition-all hover:border-themeButtonHover-0  whitespace-nowrap    border-[2px]"
          : "text-white"
      }`}
    >
      {text}
    </Link>
  );
};

export default LinkComponent3;
