import React, { useEffect, useState } from "react";
import InputField from "../InputField";
import ButtonComponent from "../ButtonComponent";
import { useForm } from "react-hook-form";
import axios from "axios";
import { setCookie } from "../../../hooks/useCookies";
import { API } from "../../../api";
import { useDispatch } from "react-redux";
import { authorizedUser, setUser } from "../../../store/slices/userSlice";
import { generateRandomPassword } from "../../../utils/password";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { code } from "@nextui-org/react";
import ForgetPassLoader from "../ForgetPassLoader";

const StepSignUP = ({ id }) => {
  const { register, getValues, setValue, handleSubmit, errors } = useForm();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");

  const [userEmail, setUserEmail] = useState(null);
  const [passwordError, setPasswordError] = useState("");
  const [newToken, setNewToken] = useState("");

  const getCountry = async () => {
    try {
      const response = await axios.get("https://api.country.is");
      const code = response?.data?.country;

      if (code) {
        const countryResponse = await axios.get(
          `https://restcountries.com/v3.1/alpha/${code}`
        );
        const countryName = countryResponse?.data[0]?.name?.common;
        setCountry(countryName);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCountry();
  }, []);

  const pwd = generateRandomPassword(12);

  const onSubmit = async () => {
    setLoading(true);

    const email = getValues("email");
    try {
      const response = await API.registerUserTemp({
        firstName: "N/A",
        lastName: "N/A",
        email,
        password: pwd,
        country: country,
      });
      setLoading(false);

      setCookie("token", response?.data?.data?.token);
      // navigate(`/checkout?id=${id}`);
      dispatch(authorizedUser());
    } catch (error) {
      console.log(error, "error");
      setLoading(false);

      if (error?.response?.status === 409) {
        setUserEmail(email);
        setStep(1);
        setValue("password", ""); // Clear password value when moving to the next step
      }
    }
  };

  const handleLogin = async () => {
    setLoading(true);
    const password = getValues("password");

    try {
      const response = await API.logInUser({
        email: userEmail,
        password: password,
      });

      setLoading(false);
      setCookie("token", response?.data?.data?.token);
      dispatch(setUser(response?.data?.data));
      // navigate(`/checkout?id=${id}`);
    } catch (error) {
      setLoading(false);
      setPasswordError(error?.response?.data?.message);
    }
  };

  const [otpLoader, setOtpLoader] = useState(false);

  const handleOTP = async () => {
    setOtpLoader(true);
    try {
      await API.forgetPasswordOtp({
        email: userEmail,
      });
      setOtpLoader(false);
      setStep(2);
      setPasswordError("");
    } catch (error) {
      console.log(error);
      setPasswordError(error?.response?.data?.message);
      setOtpLoader(false);
    }
  };
  const handleVerifyOTP = async () => {
    setLoading(true);
    try {
      const response = await API.forgetPasswordVerifyOtp({
        email: userEmail,
        code: otp,
      });

      setNewToken(response?.data?.data);
      setLoading(false);
      setStep(3);
      setPasswordError("");
    } catch (error) {
      setPasswordError(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleResetPass = async () => {
    setLoading(true);
    const newPassword = getValues("newPassword");

    try {
      await API.resetPassword({
        token: newToken,
        password: newPassword,
      });

      const response = await API.logInUser({
        email: userEmail,
        password: newPassword,
      });

      setCookie("token", response?.data?.data?.token);
      dispatch(setUser(response?.data?.data));
      // navigate(`/checkout?id=${id}`);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="w-full lg:max-w-[550px] flex flex-col h-full justify-center items-center">
      {step === 0 && (
        <>
          <h2 className="text-3xl mb-4 font-medium text-center">
            Enter Your Email
          </h2>

          {country && (
            <div className="grid grid-col-1 gap-2 w-full">
              <InputField
                type="text"
                placeholder="Email"
                errors={errors}
                name="email"
                register={register}
                autoComplete="off"
              />

              <div className="mt-4 w-full">
                <ButtonComponent
                  type={"submit"}
                  text="Next"
                  onClick={onSubmit}
                  loading={loading}
                  isActive={true}
                />
              </div>
            </div>
          )}
        </>
      )}

      {step === 1 && (
        <>
          <h2 className="text-3xl mb-4 font-medium text-center">
            Enter Your Password
          </h2>

          <div className="grid grid-col-1 gap-2 w-full">
            <InputField
              key="password"
              type="password"
              placeholder="Password"
              errors={errors}
              name="password"
              register={register}
              autoComplete="new-password"
            />
            {passwordError && (
              <p className="text-tiny text-danger pl-2 mt-1">{passwordError}</p>
            )}

            <div className="mt-4 w-full">
              <ButtonComponent
                type={"submit"}
                text="Proceed"
                onClick={handleLogin}
                loading={loading}
                isActive={true}
              />
            </div>
            <div className="flex justify-center items-center gap-4 my-3">
              <div
                onClick={handleOTP}
                className="underline cursor-pointer text-start table text-themePrimary-0 hover:text-themeSecondry-0 font-normal"
              >
                Forgot Password? Click to recover
              </div>
              {otpLoader && <ForgetPassLoader />}
            </div>
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <h2 className="text-3xl mb-4 font-medium text-center">
            Enter Otp to continue
          </h2>

          <div className="grid grid-col-1 gap-2 w-full">
            <div className="w-full flex justify-center items-center gap-5">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                containerStyle={"otpCodeClass"}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{
                  border: "1px solid #C70048",
                  borderRadius: "12px ",
                  width: "54px",
                  height: "54px",
                  fontSize: "20px",
                  color: "#C70048",
                  fontWeight: "700",
                  caretColor: "blue",
                }}
                focusStyle={{
                  border: "1px solid #243656",
                  outline: "none",
                }}
              />
            </div>

            {passwordError && (
              <p className="text-tiny text-danger text-center pl-2 mt-1">{passwordError}</p>
            )}

            <div className="mt-4 w-full">
              <ButtonComponent
                type={"submit"}
                text="Verify"
                onClick={handleVerifyOTP}
                loading={loading}
                isActive={true}
              />
            </div>

            <div className="flex justify-center items-center gap-4 my-3">
              <div
                onClick={handleOTP}
                className="underline cursor-pointer text-start table text-themePrimary-0 hover:text-themeSecondry-0 font-normal"
              >
                Didnt receive otp? Click to resend
              </div>
              {otpLoader && <ForgetPassLoader />}
            </div>
          </div>
        </>
      )}

      {step === 3 && (
        <>
          <h2 className="text-3xl mb-4 font-medium text-center">
            Confirm Your New Password
          </h2>

          <div className="grid grid-col-1 gap-2 w-full">
            <InputField
              key="password2"
              type="password"
              placeholder="Enter new password"
              errors={errors}
              name="newPassword"
              register={register}
              autoComplete="newPassword"
            />
            {passwordError && (
              <p className="text-tiny text-danger pl-2 mt-1">{passwordError}</p>
            )}

            <div className="mt-4 w-full">
              <ButtonComponent
                type={"submit"}
                text="Confirm"
                onClick={handleResetPass}
                loading={loading}
                isActive={true}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StepSignUP;
