import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  examData: null,
};

const examSlice = createSlice({
  name: "exam",
  initialState,
  reducers: {
    createUserExam(state, action) {
      state.examData = action.payload;
    },

    clearExam(state) {
      state.examData = initialState.examData;
    },
  },
});

export const { createUserExam, clearExam } = examSlice.actions;
export default examSlice.reducer;
