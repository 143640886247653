import { toast } from "react-toastify";

export const successToast = (response) => {
  toast(response || "hello", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    backgroundColor: "crimson",
  });
};

export const errorToast = (
  error,
  defaultError = "Can not perform this action. Try again later"
) => {
  console.log(error?.response?.data?.message?.length , "error?.response?.data?.message?.length")
  if (error?.response?.data?.message?.length <= 100) {
    toast.error(error?.response?.data?.message || defaultError);
  } else {
    toast.error(defaultError);
  }
};
