const reportArray = [
  {
    id: "invalidInformation",
    name: "I have found Invalid Information",
  },
  {
    id: "concern",
    name: "I have concerns on this question",
  },
  {
    id: "somewhereElse",
    name: "I have found this question somewhere else",
  },
  {
    id: "copyright",
    name: "The information provided is violating copyright law",
  },
];

export { reportArray };
