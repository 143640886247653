export const labValues = [
  {
    category: "Electrolytes",
    values: [
      "Ammonia: 15-50 µmol/L",
      "Ceruloplasmin: 15-60 mg/dL",
      "Chloride: 95-105 mmol/L",
      "Copper: 70-150 µg/dL",
      "Creatinine: 0.8-1.3 mg/dL",
      "Blood urea nitrogen: 8-21 mg/dL",
      "Ferritin: 12-300 ng/mL (men), 12-150 ng/mL (women)",
      "Glucose: 65-110 mg/dL",
      "Inorganic phosphorous: 1-1.5 mmol/L",
      "Ionized calcium: 1.03-1.23 mmol/L",
    ],
  },
  {
    category: "Hematology",
    values: [
      "Hemoglobin: 13-17 g/dL (men), 12-15 g/dL (women)",
      "Hematocrit: 40%-52% (men), 36%-47%",
      "Glycosylated hemoglobin: 4%-6%",
      "Mean corpuscular volume (MCV): 80-100 fL",
      "Red blood cell distribution width (RDW): 11.5%-14.5%",
      "Mean corpuscular hemoglobin (MCH): 0.4-0.5 fmol/cell",
      "Mean corpuscular hemoglobin concentration (MCHC): 30-35 g/dL",
      "Reticulocytes: 0.5%-1.5%",
      "White blood cells (WBC): 4-10 x 10^9/L",
      "Neutrophils: 2-8 x 10^9/L",
    ],
  },
  {
    category: "Lipids",
    values: [
      "Triglycerides: 50-150 mg/dL",
      "Total cholesterol: 3-5.5 mmol/L",
      "High-density lipoprotein (HDL): 40-80 mg/dL",
      "Low-density lipoprotein (LDL): 85-125 mg/dL",
      "Chylomicrons: 0-200 mg/dL",
      "Very low-density lipoprotein (VLDL): 5-40 mg/dL",
      "Lipoprotein (a): 0-300 mg/dL",
      "Free fatty acids: 0.1-0.7 mmol/L",
      "Beta-lipoprotein: 5-10 mg/dL",
      "Cholesterol to HDL ratio: 3.5-6",
    ],
  },
  {
    category: "Acid base",
    values: [
      "pH: 7.35-7.45",
      "Base excess: (-3)-(+3)",
      "H+: 36-44 nmol/L",
      "Partial pressure of oxygen (pO2): 75-100 mm Hg",
      "Oxygen saturation: 96%-100%",
      "Partial pressure of carbon dioxide (pCO2): 35-45 mm Hg",
      "Bicarbonate (HCO3): 18-22 mmol/L",
      "Anion gap: 3-11 mmol/L",
      "Serum osmolality: 275-295 mOsm/kg",
      "Plasma osmolality: 285-295 mOsm/kg",
    ],
  },
  {
    category: "Gastrointestinal function",
    values: [
      "Albumin: 35-50 g/L",
      "Alkaline phosphatase: 50-100 U/L",
      "Alanine aminotransferase (ALT): 5-30 U/L",
      "Amylase: 30-125 U/L",
      "Aspartate aminotransferase (AST): 5-30 U/L",
      "Direct bilirubin: 0-6 µmol/L",
      "Gamma glutamyl transferase: 6-50 U/L",
      "Lipase: 10-150 U/L",
      "Total bilirubin: 2-20 µmol/L",
      "Total protein: 60-80 g/L",
    ],
  },
  {
    category: "Cardiac enzymes",
    values: [
      "Creatine kinase: 25-200 U/L",
      "Creatine kinase MB (CKMB): 0-4 ng/mL",
      "Troponin: 0-0.4 ng/mL",
      "Lactate dehydrogenase (LDH)",
    ],
  },
];
