import React from "react";
import { Hourglass, Oval, TailSpin } from "react-loader-spinner";

const ForgetPassLoader = () => {
  return (
    <TailSpin
    visible={true}
    height="30"
    width="30"
    color="#C70048"
    ariaLabel="tail-spin-loading"
    radius="1"
    wrapperStyle={{}}
    wrapperClass=""
    />
  );
};

export default ForgetPassLoader;
