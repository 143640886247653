import React, { useState } from "react";
import ButtonComponent from "../../components/general/ButtonComponent";
import InputField from "../../components/general/InputField";
import { changePassword } from "../../validations/account";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorToast, successToast } from "../../hooks/useToast";
import { API } from "../../api";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(changePassword) });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await API.changePassword({
        oldPassword: data?.oldPassword,
        newPassword: data?.newPassword,
      });
      reset();
      setLoading(false);
      successToast("Password Changed Successfully");
    } catch (error) {
      errorToast(error, "Can not Change Password");
      setLoading(false);
    }
  };

  return (
    <div className="page-comp bg-white max-w-[600px] w-full mx-auto py-12    ">
     

      <div className="form-area">
        <h2 className=" text-center">Change Password</h2>

        <form
          className="grid grid-col-1 gap-6 my-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputField
            label="Old Password"
            type="password"
            placeholder="Enter your current Password"
            errortext="Current password is Required"
            errors={errors}
            name="oldPassword"
            register={register}
          />
          <InputField
            label="New Password"
            type="password"
            placeholder="Enter Your New Password"
            errortext="New Password Is Required"
            errors={errors}
            name="password"
            register={register}
          />
          <InputField
            label="Confirm New Password"
            type="password"
            placeholder="Confirm Your New Password"
            errortext={errors?.newPassword?.message}
            errors={errors}
            name="newPassword"
            register={register}
          />
          <div className="w-full ">
            <ButtonComponent
              isActive={true}
              type={"submit"}
              text="Change"
              loading={loading}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
