import React from "react";
import DashBoardSidebar from "./DashBoardSidebar";

import DashBoardBody from "./DashBoardBody";
import { pageslist } from "../../../data/pageslist";
import MetaData from "../../seo/MetaData";

const UserDashboard = () => {
  return (
    <div className="profile w-full pagelayout  ">
      <MetaData title={"Dashboard | Q Bank Model"} />
      <div className="HomeAbout ">
        <div className=" w-full  mx-auto flex flex-wrap md:flex-nowrap relative bg-[#F5F5F5] ">
          <div className="w-full md:w-[18%]  md:h-screen top-0 px-0 bg-white-0 sticky  z-50   ">
            <DashBoardSidebar pageslist={pageslist} />
          </div>
          <div className="w-full md:w-[88%]    ">
            <DashBoardBody />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
