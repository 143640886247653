import React from "react";
import ResetPassword from "../../components/user/resetPass/ResetPassword";
import MetaData from "../../components/seo/MetaData";

const ResetPasswordPage = () => {
  return (
    <>
      <MetaData
        title={"Reset Password - Qbank Model"}
        description={
          "Reset your Qbank Model password to regain access to your account. Follow the easy steps for a secure update."
        }
        keywords={
          "reset password, Qbank Model account, password update, secure login"
        }
      />

      <ResetPassword />;
    </>
  );
};

export default ResetPasswordPage;
