import React from "react";
import LinkComponent from "../general/LinkComponent";

const AboutComponent = ({
  image,
  heading,
  heading2,
  paragh,
  paragh2,
  show = false,
  reverse = false,
}) => {
  return (
    <div className="HomeAbout ">
      <div className="container w-full  mx-auto">
        <div
          className={`flex ${
            reverse ? "flex-row-reverse" : ""
          } justify-start flex-wrap lg:flex-nowrap items-center px-0 py-0 lg:px-0 lg:py-0 gap-7 lg:gap-10`}
        >
          {reverse ? (
            <div className="rounded-[20px]  flex justify-center pt-12  bg-themePrimary-0   w-full ">
              <img
                width={1000}
                height={1000}
                src={image}
                alt="doctors"
                className="max-w-[65%]"
                fetchpriority="high"
              />
            </div>
          ) : (
            <div className="w-full ">
              <img
                src={image}
                alt={"side image"}
                width={1000}
                height={1000}
                className="w-full !text-center mx-auto"
              />
            </div>
          )}

          <div className="w-full flex flex-col gap-5  ">
            <h2 className=" ">{heading}</h2>
            <p className=" ">{paragh}</p>
            {heading2 && (
              <>
                <h2 className=" ">{heading2}</h2>
                <p className=" ">{paragh2}</p>
              </>
            )}

            {show && (
              <div className="mt-4">
                <LinkComponent to={"about"} text={"Explore More"} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutComponent;
