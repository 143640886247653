import React, { useEffect, useState } from "react";
import PricingComponent from "../../components/pricing/PricingComponent";
import { API } from "../../api";
import Loader from "../../components/general/Loader";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import MetaData from "../../components/seo/MetaData";

const Pricing = () => {
  const [data, setData] = useState(null);
  const authorized = useSelector((state) => state.user.user.authorized);
  const [activeSubsription, setActiveSubscription] = useState(null);
  const getData = async () => {
    let response;
    try {
      response = await API.getAllSubscription();
      setData(response?.data?.data);

      // response = await API.getUserSummner();
      // setActiveSubscription(response?.data?.data?.userSubscriptionId);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const filderData = data?.filter((item) => item?.isLive);
  filderData?.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

  return (
    <div className="py-12 bg-themeBackground-0">
      <MetaData
        title={"Qbank Model Subscriptions - AMC MCQ Exam Prep Plans"}
        description={
          "Select the perfect subscription plan for your AMC MCQ exam prep. Flexible options to suit every medical student's needs. Start today with the Qbank Model"
        }
        keywords={
          "Qbank Model subscriptions, AMC MCQ exam plans, medical study plans, subscription options"
        }
      />
      {filderData ? (
        <PricingComponent
          activeSubsription={activeSubsription}
          data={filderData}
          authorized={authorized}
        />
      ) : null}
    </div>
  );
};

export default Pricing;