import React from 'react'
import ForgotPassword from '../../components/user/forgetPassword/ForgotPassword'
import MetaData from '../../components/seo/MetaData'

const ForgotPasswordPage = () => {
  return (
    <>
     <MetaData
        title={"Forgot Password - Qbank Model"}
        description={
          "Recover your Qbank Model account by resetting your password. Secure and easy steps to regain access."
        }
        keywords={
          "forgot password, password recovery, Qbank Model login, reset password"
        }
      />
    <ForgotPassword />
    </>
  )
}

export default ForgotPasswordPage
