import React from "react";

const InnerHeader = ({ text }) => {
  return (
    <div className="container mx-auto w-full">
      <div className="container bg-themeSecondry-0 w-full h-[15vh] md:h-[25vh] flex justify-center items-center rounded-[20px]">
        <h1 className="text-center text-white uppercase">{text}</h1>
      </div>
    </div>
  );
};

export default InnerHeader;
