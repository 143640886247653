import React, { useEffect, useState } from "react";
import InputField from "../../general/InputField";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { API } from "../../../api";
import ButtonComponent from "../../general/ButtonComponent";
import { errorToast, successToast } from "../../../hooks/useToast";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../store/slices/userSlice";
import Loader from "../../general/Loader";
import { setCookie } from "../../../hooks/useCookies";
import { useQuery } from "../../../hooks/queryParam";
import MetaData from "../../seo/MetaData";
import { verifyOtpSchema } from "../../../validations/account";

const VerifyOtp = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(verifyOtpSchema) });
  const params = useQuery();
  const relocate = params.get("relocate");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [canResend, setCanResend] = useState(true);
  const [timer, setTimer] = useState(0);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const startResendTimer = () => {
    setCanResend(false);
    setTimer(60); // 60 seconds
  };


 

  useEffect(() => {
    if (timer > 0) {
      const timeout = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(timeout);
    } else {
      setCanResend(true);
    }
  }, [timer]);

  useEffect(() => {
    // if (!user?.authorized) {
    //   navigate("/sign-in");
    // }

    if (user?.otpVerified) {
      navigate("/user/dashboard/summary");
    }
  }, [user]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await API.verifyUser(data);
      successToast(response?.data?.message);
      setLoading(false);
      setCookie("token", response?.data?.data?.token);
      dispatch(setUser(response?.data?.data));
      navigate("/subscriptions");
    } catch (error) {
      setLoading(false);
      errorToast(error, "Invalid Otp");
    }
  };

  const resendOtp = async () => {
    if (canResend) {
      setPageLoader(true);
      try {
        await API.resendOtp();
        successToast("Please check your email and verify the OTP");
        startResendTimer();
        setPageLoader(false);
      } catch (error) {
        setPageLoader(false);
        errorToast(error, "Cannot resend OTP at the moment");
      }
    }
  };

  return (
    <div className="SignUp">
      <MetaData
        title={"Verify OTP - Qbank Model"}
        description={
          "Enter your OTP to verify your Qbank Model account and ensure secure access."
        }
        keywords={
          "verify OTP, Qbank Model security, account verification, OTP verification"
        }
      />
      <div className="container w-full mx-auto">
        <div className="py-6 flex justify-center lg:py-16 gap-7 lg:gap-10">
          <div className="w-full lg:max-w-[550px]">
            <h2 className="text-3xl md:text-5xl   font-medium text-center">
              Verify Your Email
            </h2>

            <p className="text-center mb-4">Check your email for code</p>
            {pageLoader ? (
              <Loader />
            ) : (
              <>
                <form
                  className="grid grid-col-1 gap-2"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <InputField
                    label="Verification Code"
                    type="text"
                    placeholder="Enter Verification Code"
                    errors={errors}
                    name="code"
                    register={register}
                  />
                  <div className="w-full mt-8">
                    <ButtonComponent
                      type={"submit"}
                      text="Verify"
                      loading={loading}
                      isActive={true}
                    />
                  </div>
                </form>
                <div className="py-4">
                  <p className="text-blue-900 cursor-pointer text-center ">
                    Didn't receive the OTP? Check your spam folder.
                  </p>
                  <p
                    onClick={resendOtp}
                    className={`text-blue-900 cursor-pointer text-center ${
                      !canResend
                        ? "text-gray-500 cursor-not-allowed"
                        : "hover:text-blue-700"
                    }`}
                    disabled={!canResend}
                  >
                    {canResend
                      ? "Click here to resend"
                      : `Resend available in ${timer}s`}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
