import React, { useEffect, useState } from "react";
import { useQuery } from "../../hooks/queryParam";
import { API } from "../../api";
import { useForm } from "react-hook-form";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  americanexp,
  card2,
  card3,
  checkoutLogo,
  circleBack,
  discover,
  logo,
  secure,
} from "../../assets";
import CheckoutForm from "../../components/stripe/CheckoutForm";
import StepSignUP from "../../components/general/Modal/StepSignUP";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Checkout = () => {
  const query = useQuery();
  const id = Number(query.get("id"));

  const authorized = useSelector((state) => state.user.user.authorized);

  const [item, setItem] = useState(null);
  const { register, getValues } = useForm();
  const [promoError, setPromoError] = useState("");
  const [promoPrice, setPromoPrice] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [stripeElementsKey, setStripeElementsKey] = useState(0);

  const createNewPaymentIntent = async () => {
    let payload = {
      subscriptionId: Number(id),
      ...(getValues("promoCode") && { promoCode: getValues("promoCode") }),
    };

    try {
      const key = await API.paymentIntend(payload);
      setClientSecret(key?.data?.data);
      setStripeElementsKey((prevKey) => prevKey + 1);
    } catch (error) {
      console.log("Error creating new PaymentIntent:", error);
    }
  };

  const handleApplyPromo = async () => {
    try {
      const promoCode = getValues("promoCode");
      if (!promoCode) {
        setPromoError("Please enter code");
        return;
      }

      const apiMethod = authorized ? API.getTotal : API.getTotalGeneral;
      const response = await apiMethod({
        subscriptionId: id,
        promoCode: promoCode,
      });

      setPromoPrice(response?.data?.data);
      setPromoError("");
      await createNewPaymentIntent();
    } catch (error) {
      console.log(error);
      setPromoPrice(null);
      setPromoError(error?.response?.data?.message);
    }
  };

  const getData = async () => {
    try {
      const response = await API.getSingleSubscription(id);
      setItem(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getStripeKeys = async () => {
    try {
      const response = await API.getPublishKey();
      setStripePromise(loadStripe(response?.data?.publishableKey));
      if (authorized) {
        await createNewPaymentIntent();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    if (authorized) {
      getStripeKeys();
    }
  }, [authorized]);

  if (!item) return <div> </div>;

  return (
    <div className=" ">
      <div className="flex justify-between items-center py-3 border-b ">
        <Link to={"/subscriptions"} className="hidden sm:block">
          <img
            src={circleBack}
            alt="back"
            width={200}
            height={100}
            className="max-w-[70px] pl-6 w-full mx-auto  "
          />
        </Link>

        <Link to={"/"} className="max-w-[220px] w-full mx-auto ">
          <img
            src={logo}
            alt="logo"
            width={200}
            height={100}
            className="max-w-[220px] w-full mx-auto "
          />
        </Link>
      </div>

      <div className="max-w-[750px] mx-auto px-3 py-2  ">
        <div className="flex   flex-col gap-[2px]">
          <h2 className="text-2xl font-bold   text-gray-800  md:mt-3 noUppercase">
            Payment Summary
          </h2>
          <p>Review the information below regarding this transaction.</p>
       
        </div>

        <div
          className={`flex flex-col ${
            authorized ? "" : "flex-row-reverse"
          } gap-2`}
        >
          <div className="flex flex-col gap-4 mt-4 border-2   w-full">
            <table className="w-full   border-collapse">
              <thead className="bg-[#F5F5F5]">
                <tr className="border-b">
                  <th className="text-center py-[5px] font-normal text-lg border-r border-gray-300">
                    Sr#
                  </th>
                  <th className="text-center py-[5px] font-normal text-lg border-r border-gray-300">
                    Description
                  </th>
                  <th className="text-center py-[5px] font-normal text-lg border-r border-gray-300">
                    Cost
                  </th>
                  <th className="text-center py-[5px] font-normal text-lg border-r border-gray-300">
                    Quantity
                  </th>
                  <th className="text-center py-[5px] font-normal text-lg  ">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b">
                  <td className="py-2 text-center border-r border-gray-300">
                    1
                  </td>
                  <td className="py-2 text-center border-r border-gray-300">
                    {item.name} Package
                  </td>
                  <td className="text-center py-2 border-r border-gray-300">
                    ${item.price.toFixed(2)}
                  </td>
                  <td className="text-center py-2 border-r border-gray-300">
                    1
                  </td>
                  <td className="text-center py-2  ">
                    ${item.price.toFixed(2)}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr className="border-b">
                  <td colSpan="4" className="text-right pr-6 py-2">
                    Subtotal
                  </td>
                  <td className="text-center py-2">${item.price.toFixed(2)}</td>
                </tr>
                {/* <tr className="border-b">
                  <td colSpan="4" className="text-right pr-6 py-2">
                    Discount
                  </td>
                  <td className="text-center py-2">
                    {" "}
                    $
                    {promoPrice
                      ? parseFloat(item.price - promoPrice).toFixed(2)
                      : "0"}
                  </td>
                </tr> */}
                <tr>
                  <td colSpan="4" className="text-right pr-6 py-2 font-bold">
                    Grand Total
                  </td>

                  <td className="text-center py-2 font-bold">
                    {promoPrice ? promoPrice.toFixed(2) : item.price.toFixed(2)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          {/* <>
            <div className="w-full relative">
              <input
                type="text"
                placeholder="Add promo code (Optional)"
                className={`w-full placeholder:text-[14px] px-4 py-4 border border-gray-300 ${
                  promoError ? "border-red-400" : ""
                } 
              ${promoPrice ? "border-green-600" : ""}
              rounded-md focus:outline-none`}
                {...register("promoCode")}
              />
              <button
                onClick={handleApplyPromo}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-themeButton-0 text-white hover:bg-themeButtonHover-0 text-sm px-4 py-3 rounded-md font-semibold transition duration-300 ease-in-out"
                type="button"
              >
                Apply Promo
              </button>
            </div>
            {promoError && (
              <p className="text-sm text-red-500 pl-1">{promoError}</p>
            )}
            {promoPrice && (
              <p className="text-sm text-green-600">
                Promo Code has been applied
              </p>
            )}
          </> */}

          <div className="flex flex-col gap-2 ">
            {/* <h2 className="text-2xl font-bold   text-gray-800 mt-4 noUppercase">
              Billing Information
            </h2>
            <p className="capitalize">enter your payment details</p> */}

            <div className="flex justify-between mt-2 flex-wrap md:flex-nowrap items-center ">
              <h2 className="text-2xl font-bold    text-gray-800   noUppercase">
                Cards Accepted
              </h2>
              <div className=" flex  flex-nowrap  gap-1  ">
                <img
                  src={card2}
                  alt="card1"
                  width={100}
                  height={150}
                  className="max-w-[80px] w-full object-center shadow-lg"
                />
                <img
                  src={card3}
                  alt="card1"
                  width={100}
                  height={150}
                  className="max-w-[80px] w-full object-center shadow-lg"
                />
                <img
                  src={americanexp}
                  alt="card1"
                  width={100}
                  height={150}
                  className="max-w-[80px] w-full object-center shadow-lg"
                />
                <img
                  src={discover}
                  alt="card1"
                  width={100}
                  height={150}
                  className="max-w-[80px] w-full object-center shadow-lg"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <div className="flex items-center gap-2 mt-3 justify-start">
              <h2 className="text-2xl font-bold   text-gray-800  noUppercase">
                Credit/Debit Card
              </h2>
              <div className="flex justify-start gap-1 items-center ">
                <div>
                  <img src={secure} alt="secure" width={18} height={18} />
                </div>
                <p>Secure Checkout</p>
              </div>
            </div>

            {authorized ? (
              <div className="mt-5">
                {clientSecret && stripePromise && (
                  <Elements
                    key={stripeElementsKey}
                    stripe={stripePromise}
                    options={{ clientSecret }}
                  >
                    <CheckoutForm clientSecret={clientSecret} />
                  </Elements>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
