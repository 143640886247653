import React, { useEffect } from "react";
import InnerHeader from "../../components/general/InnerHeader";
import AboutComponent from "../../components/home/AboutComponent";
import { HomeAbout, doctors } from "../../assets";
import ModelHelp from "../../components/home/ModelHelp";
import { aboudBoxData } from "../../data/homepage";
import LinkComponent from "../../components/general/LinkComponent";
import { Helmet } from "react-helmet";
import MetaData from "../../components/seo/MetaData";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="home w-full pagelayout">
      <MetaData
        title={"About Qbank Model - Your AMC MCQ Exam Partner"}
        description={
          " Learn about Qbank Model, your trusted partner in AMC MCQ exam preparation. Our mission is to help medical students excel with quality resources."
        }
        keywords={
          "about Qbank Model, AMC MCQ exam partner, medical education, exam preparation support"
        }
      />
      <InnerHeader text={"About us"} />
      <AboutComponent
        image={HomeAbout}
        heading={
          <>
            Explore about <br /> qbank model
          </>
        }
        paragh={
          "At Q Bank Model, we are dedicated to helping aspiring medical professionals achieve their dreams of practicing medicine in Australia. Our mission is to provide the most comprehensive and effective preparation resources for the Australian Medical Licensing Exam. We understand the challenges and rigorous demands of this critical examination, and we are here to support you every step of the way."
        }
      />
      <AboutComponent
        reverse={true}
        image={doctors}
        heading={<>Who We Are</>}
        paragh={
          "Q Bank Model is a team of experienced medical professionals, educators, and technologists who are passionate about medical education. Our diverse backgrounds and expertise converge to create a unique and powerful learning platform. We are committed to providing high-quality, up-to-date content that is essential for your success on the AMC MCQ Exam."
        }
        heading2={"Our vision"}
        paragh2={
          "Our vision is to become the leading resource for AMC online test preparation, empowering medical graduates from around the world to succeed in their licensing exams and pursue their medical careers in Australia. We strive to make the preparation process more efficient, effective, and accessible."
        }
      />

      <ModelHelp
        twoGrids={true}
        data={aboudBoxData}
        heading={"What We Offer"}
      />

      <div className="container mx-auto w-full text-center flex flex-col gap-8 !mb-16 ">
        <h2 className="text-center">Our Commitment</h2>
        <p>
          At Q Bank Model, we are committed to your success. We continuously
          update our content to reflect the latest medical practices and AMC
          guidelines. Our support team is always available to assist you with
          any questions or concerns you may have. We are here to provide you
          with the tools and confidence you need to excel in the AMC MCQ Exam.
        </p>
        <p2>
          Join the growing community of successful candidates who have trusted Q
          Bank Model for their AMC MCQ Exam preparation. Let us help you achieve
          your goal of becoming a licensed medical professional in Australia.
          Together, we can make your dream a reality.
        </p2>
        <div className="lg:px-0 lg:py-6   mx-auto text-center">
          <LinkComponent to="/sign-up" text="Register Now" varient2={false} />
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
