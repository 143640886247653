import React from "react";

const Chip = ({ text }) => {
  return (
    <div className="rounded-[50px] whitespace-nowrap px-6 py-2 w-fit bg-themeButton-0/10 text-themeButton-0">
      {text}
    </div>
  );
};

export default Chip;
