import React from "react";

const ProgressChart = ({ percentage }) => {
  const radius = 15.9155;
  const circumference = 2 * Math.PI * radius;
  const strokeDasharray = `${
    (percentage  / 100) * circumference
  } ${circumference}`;
  const strokeDashoffset = circumference * ((100 - percentage) / 100);

  return (
    <div className="relative w-[220px] h-[220px] rounded-lg p-4 mx-auto">
      <svg viewBox="0 0 36 36" className="w-full h-full transform -rotate-90">
        <circle
          cx="18"
          cy="18"
          r={radius}
          fill="none"
          stroke="#D8D8D8"
          strokeWidth="3.8"
        />
        <circle
          cx="18"
          cy="18"
          r={radius}
          fill="none"
          stroke="#C70048"
          strokeWidth="3.8"
          strokeDasharray={strokeDasharray}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
        />
      </svg>
      <div className="absolute inset-0 flex items-center justify-center">
        <span className="text-themeButton-0 text-5xl font-bold px-2">
          {percentage || 0}%
        </span>
      </div>
      {/* <div className="absolute -top-3 left-4   bg-white rounded-full w-10 h-10 flex items-center justify-center">
        <span className="text-gray-800 text-sm font-bold">
          {remainingPercentage}%
        </span>
      </div>
      <div className="absolute bottom-4 right-4 bg-white rounded-full w-10 h-10 flex items-center justify-center">
        <span className="text-gray-800 text-sm font-bold">{percentage}%</span>
      </div> */}
    </div>
  );
};

export default ProgressChart;
