import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { logo, newProfile, UserProfile } from "../../../assets";
import { HiOutlineLogout } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { GiHamburgerMenu } from "react-icons/gi";
import { deleteCookie } from "../../../hooks/useCookies";
import { logoutUser } from "../../../store/slices/userSlice";
import { successToast } from "../../../hooks/useToast";

const DashBoardSidebar = ({ pageslist }) => {
  const location = useLocation();
  const user = useSelector((state) => state.user.user);
  const [toggled, setIsToggled] = useState(false);
  const handleClick = () => {
    setIsToggled(!toggled); // Toggle the state
  };

  const dispatch = useDispatch();

  const handleLogout = () => {
    deleteCookie("token");
    dispatch(logoutUser());
    successToast("Successfully logged out");
  };

  return (
    <>
      <div className="w-full md:h-[15%] overflow-hidden  flex justify-between md:justify-center items-center px-4 md:px-0 py-8 md:py-10 bg-themeBackground-0 relative logo-area">
        <img
          src={logo}
          alt="q bank model"
          className="w-[180px] lg:max-w-[200px]"
        />
        <div className="left-bar flex md:hidden  flex-row items-center gap-3 ">
          <img
            src={user?.imageUrl || UserProfile}
            alt="user profile"
            className="w-[40px] h-[40px] rounded-full"
          />
          <GiHamburgerMenu
            onClick={handleClick}
            className="text-3xl text-text-black hover:themePrimary-0"
          />
        </div>
      </div>
      <div
        className={`transition-all duration-300 ${
          toggled ? `translate-x-0` : `-translate-x-[767px]`
        }  md:translate-x-0 absolute lg:static overflow-hidden w-full bg-white flex flex-col justify-between md:h-[85%]`}
      >
        <nav className="p-0 divide-y overflow-visible md:mt-6 pt-6 pb-4 md:py-0">
          <ul className="list-none p-0 m-0">
            {pageslist.map((item, key) => {
              const isActive = location.pathname === item.link;
              return (
                <li
                  key={key}
                  className={`px-4 h-10 transition-colors my-1 hover:bg-themeButton-0 group   rounded-tr-[24px] rounded-br-[24px]  max-w-[85%] w-full  ${
                    isActive ? "bg-themeButton-0  " : ""
                  }  `}
                >
                  <Link
                    to={item?.link}
                    onClick={handleClick}
                    className="flex items-center justify-start  h-full w-full py-3 no-underline text-inherit"
                  >
                    <div
                      className={`${
                        isActive ? "text-white" : "text-themeSecondry-0 "
                      }  w-9 h-9 flex group-hover:text-white justify-center items-center`}
                    >
                      {item.icon}
                    </div>

                    <div>
                      <p
                        className={` ${
                          isActive ? "text-white" : "text-themeSecondry-0"
                        }  group-hover:text-white text-sm`}
                      >
                        {item.item}
                      </p>
                    </div>
                  </Link>
                </li>
              );
            })}
            <li
              className="block md:hidden cursor-pointer"
              onClick={handleLogout}
            >
              <div className="px-4 h-10 transition-colors my-1 rounded-br-[24px]  max-w-[85%]  flex flex-row py-2 items-center gap-2">
                <HiOutlineLogout className="text-2xl ml-3" />
                <h4 className="text-left capitalize">logout</h4>
              </div>
            </li>
          </ul>
        </nav>

        <div
          onClick={handleLogout}
          className=" cursor-pointer px-4 h-10 transition-colors my-1 rounded-br-[24px]  max-w-[85%]  hidden md:block"
        >
          <div className="flex flex-row items-center gap-3">
            <HiOutlineLogout className="text-2xl" />
            <h4 className="text-left capitalize">logout</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoardSidebar;
