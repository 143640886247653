import { FaTachometerAlt } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaUserGraduate } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa6";
import { TbLayoutDashboardFilled } from "react-icons/tb";
import { BsClipboardMinusFill } from "react-icons/bs";

const   pageslist = [
  {
    item: "Dashboard",
    icon: <TbLayoutDashboardFilled  />,
    link: "/user/dashboard/summary",
  },
  {
    item: "My Exams",
    icon: <BsClipboardMinusFill />,
    link: "/user/dashboard/exam",
  },
  {
    item: "My Profile",
    icon: <FaUser />,
    link: "/user/dashboard/profile",
  },
  {
    item: "Subscriptions",
    icon: <FaBriefcase />,
    link: "/user/dashboard/all-subscriptions",
  },
  // {
  //   item: "Help & Support",
  //   icon: <MdContactSupport />,
  //   link: "/",
  // },
];
export { pageslist };
