import React from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Chip,
} from "@nextui-org/react";
import { Editor } from "primereact/editor";
import ResultDetailModal from "./ResultDetailModal";
import ResultProgressBar from "./ResultProgressBar";
import { CheckSvg, CrossSvg } from "../../../data/svgs";
import LinkComponent from "../../general/LinkComponent";
import MetaData from "../../seo/MetaData";

const Result = ({ data }) => {
  return (
    <div className="w-full flex flex-col gap-5">
            <MetaData title={"Result | Q Bank Model"} />

      <div className="w-full">
        <div className="flex justify-between bg-themeSecondry-0 rounded-[8px] rounded-b-none ">
          <h3 className="text-white text-[30px] text-center w-full font-semibold py-3">
            Exam Result
          </h3>
        </div>
        <div className="  px-3 md:px-12 grid grid-cols-1 md:grid-cols-4   place-items-start md:place-items-center   font-semibold   bg-themeButton-0 text-white py-4 rounded-[8px] rounded-t-none">
          <div className=" flex justify-between w-full md:w-fit gap-3 ">
            <h6>No of Questions</h6>
            <p>{data?.examQuestions?.length}</p>
          </div>
          <div className="flex justify-between w-full md:w-fit gap-3 ">
            <h6>Correct Answers</h6>
            <p>{data?.totalCorrect}</p>
          </div>
          <div className="flex justify-between w-full md:w-fit gap-3 ">
            <h6>Wrong Answers</h6>
            <p>{data?.totalWrong}</p>
          </div>
          <div className="flex items-center  gap-3 md:w-[280px] w-full ">
            <h6>Percentage</h6>
            <ResultProgressBar value={parseInt(data?.ratio * 100)} />
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col gap-4  ">
        {data?.examQuestions?.map((item, index) => (
          <div
            key={index}
            className="w-full border border-black  rounded-[25px]  p-5 flex flex-col gap-3"
          >
            <div className="flex justify-between items-center">
              <p className="whitespace-nowrap font-semibold">
                Question # {index + 1}
              </p>

              <div className="flex justify-start items-center gap-3">
                <div
                  className={`${
                    item?.isCorrect ? "bg-themeSecondry-0" : " bg-themeButton-0"
                  }
                  px-3 max-w-[100px] w-full !py-[10px] text-white text-sm font-medium rounded-[8px] flex items-center gap-2
                  `}
                >
                  <span className="rounded-full h-5 w-5 bg-white flex justify-center items-center">
                    {item?.isCorrect ? <CheckSvg /> : <CrossSvg />}{" "}
                  </span>{" "}
                  {item?.isCorrect ? "Correct" : "Wrong"}
                </div>
                <ResultDetailModal item={item} number={index + 1} />
              </div>
            </div>
            <Editor
              value={item?.questionName}
              readOnly
              style={{ height: "100%", width: "100%" }}
              headerTemplate={<></>}
            />
          </div>
        ))}
      </div>

      <div className="flex  flex-col justify-center items-center gap-4">
        <h4>What's Next ?</h4>
        <div className="flex   justify-center items-center gap-4">
          <LinkComponent to={"/user/dashboard/exam"} text={"My Exams"} />
          <LinkComponent to={"/user/exam"} text={"Create Another Exam"} />
        </div>
      </div>
    </div>
  );
};

export default Result;
