import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import InnerHeader from "../../components/general/InnerHeader";
import { IoMdArrowDropdown } from "react-icons/io";
import { Link } from "react-router-dom";
import MetaData from "../../components/seo/MetaData";

const FaqPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const faqData = [
    {
      question: "What is the AMC CAT exam?",
      answer:
        "The AMC CAT exam is a computer-based test that assesses the clinical knowledge of medical graduates seeking to practice in Australia.",
    },
    {
      question: "How many questions are in the AMC CAT exam?",
      answer:
        "The AMC CAT exam consists of 150 multiple-choice questions (MCQs), each testing various clinical subjects.",
    },
    {
      question: "How long is the AMC CAT exam?",
      answer:
        "The AMC CAT exam is 3.5 hours long, giving candidates enough time to answer the questions and demonstrate their clinical knowledge.",
    },
    {
      question: "What subjects are covered in the AMC CAT exam?",
      answer:
        "Subjects covered include medicine, surgery, pediatrics, obstetrics, gynecology, and psychiatry, among other essential clinical areas.",
    },
    {
      question: "How is the AMC CAT exam scored?",
      answer:
        "The exam is scored based on the number of correct answers. There is no penalty for incorrect answers, and the results are pass/fail.",
    },
    {
      question: "How often can I take the AMC CAT exam?",
      answer:
        "You can retake the AMC CAT exam after a 3-month waiting period between attempts.",
    },
    {
      question: "What is the passing score for the AMC CAT exam?",
      answer:
        "The passing score varies depending on the exam's difficulty, but you need to score above a certain threshold to pass.",
    },
    {
      question: "What is the format of the AMC CAT exam questions?",
      answer:
        "The questions are multiple-choice, with each having one correct answer. These questions are designed to assess your clinical decision-making skills.",
    },
    {
      question: "Where can I take the AMC CAT exam?",
      answer:
        "The exam is available at Pearson VUE test centers worldwide, and you can choose your preferred location during the registration process.",
    },
    {
      question: "Is the AMC CAT exam difficult?",
      answer:
        "The exam can be challenging, but with thorough preparation using resources like Q Bank Model, it is achievable.",
    },
    {
      question: "Are there any eligibility requirements for the AMC CAT exam?",
      answer:
        "Yes, you must hold a recognized medical degree and meet specific eligibility criteria outlined by the Australian Medical Council.",
    },
    {
      question: "How can I prepare for the AMC CAT exam?",
      answer: (
        <>
          Q Bank Model offers a comprehensive AMC question bank and practice
          tests to help you prepare. Visit{" "}
          <Link
            className="text-themeButtonHover-0 hover:text-themeButtonHover-0 font-bold"
            to={"https://www.qbankmodel.com"}
          >
            https://www.qbankmodel.com
          </Link>{" "}
          for more information.,
        </>
      ),
    },
    {
      question: "What happens after I pass the AMC CAT exam?",
      answer:
        "After passing, you may proceed to the clinical exams or fulfill other requirements needed for medical registration in Australia.",
    },
    {
      question: "Are Q Bank Model questions written by doctors?",
      answer:
        "Yes, all questions in the Q Bank Model are written and reviewed by qualified doctors to ensure accuracy and relevance.",
    },
    {
      question: "Is Q Bank Model’s payment method secure?",
      answer:
        "Yes, all payments made through Q Bank Model are processed through secure and trusted payment gateways.",
    },
    {
      question: "How can I subscribe for AMC CAT exam preparation?",
      answer:
        "To subscribe, visit Q Bank Model's subscription page, select a suitable plan, and complete the payment process.",
    },
    {
      question: "How can I check trial questions on Q Bank Model?",
      answer:
        "You can sign up for a free account to access a selection of trial questions on Q Bank Model's platform.",
    },
    {
      question: "Do you offer refunds?",
      answer:
        "Q Bank Model does not offer refunds. Please review the terms and conditions before purchasing.",
    },
    {
      question: "How do I take support from Q Bank Model?",
      answer: (
        <>
          Support is available through Quick Chat, by emailing
          <a
            href="mailto:support@qbankmodel.com"
            className="text-themeButtonHover-0 hover:text-themeButtonHover-0 font-bold"
          >
            {""} support@qbankmodel.com
          </a>{" "}
          or by submitting a query through the contact form.
        </>
      ),
    },
    {
      question: "How can I track my progress on Q Bank Model?",
      answer:
        "You can monitor your performance using detailed analytics available on your personal dashboard, which helps you track your preparation progress.",
    },
  ];

  const [openFaq, setOpenFaq] = useState(0);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  return (
    <>
      <div className="home  w-full pagelayout mx-auto mb-14">
        <MetaData
          title={"FAQs - Qbank Model Exam Preparation Support "}
          description={
            "Find answers to commonly asked questions about Qbank Model's exam preparation services. Get the support you need to succeed."
          }
          keywords={
            "FAQs, Qbank Model, exam preparation, support, common questions, Qbank FAQs, AMC MCQ question bank, AMC exam questions, MCQ exam preparation"
          }
        />

        <InnerHeader text={"FREQUENTLY ASKED QUESTIONS"} />

        <div className="container flex flex-col gap-4">
          {faqData.map((faq, index) => (
            <div
              key={index}
              className="rounded-[12px] w-full flex flex-col gap-2 bg-themeBackground-0 px-3 py-4 overflow-hidden"
            >
              <div
                onClick={() => toggleFaq(index)}
                className="flex justify-between items-center cursor-pointer"
              >
                <p className="font-bold">{faq.question}</p>
                <IoMdArrowDropdown
                  className={`text-3xl text-themeSecondryDark-0 transition-transform duration-300 ${
                    openFaq === index ? "rotate-180" : ""
                  }`}
                />
              </div>
              <div
                className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
                  openFaq === index ? "max-h-96" : "max-h-0"
                }`}
              >
                <p className="pt-2">{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FaqPage;
