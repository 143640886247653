import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import LinkComponent from "../LinkComponent";
import { API } from "../../../api";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../stripe/CheckoutForm";
import { redCheck, secure, stripeLogo } from "../../../assets";
import StepSignUP from "./StepSignUP";
import { getCookie } from "../../../hooks/useCookies";

const SubscriptionModal = ({
  id,
  authorized,
  activeSubsription,
  error,
  text,
  item,
}) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const navigate = useNavigate();
  const { register, getValues, watch } = useForm();

  const [promoError, setPromoError] = useState("");
  const [promoPrice, setPromoPrice] = useState(null);
  const [renderIntend, setRenderIntend] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [stripeElementsKey, setStripeElementsKey] = useState(0);

  const createNewPaymentIntent = async () => {
    let payload;

    if (getValues("promoCode")) {
      payload = {
        subscriptionId: Number(id),
        promoCode: getValues("promoCode"),
      };
    } else {
      payload = {
        subscriptionId: Number(id),
      };
    }

    try {
      const key = await API.paymentIntend(payload);
      setClientSecret(key?.data?.data);
      // Force re-render of Stripe Elements
      setStripeElementsKey((prevKey) => prevKey + 1);
    } catch (error) {
      console.log("Error creating new PaymentIntent:", error);
    }
  };

  const handleApplyPromo = async () => {
    try {
      let response;

      let isEmpty = getValues("promoCode");

      console.log(isEmpty);

      if (isEmpty.length > 0) {
        if (authorized) {
          if (getValues("promoCode")) {
            response = await API.getTotal({
              subscriptionId: id,
              promoCode: getValues("promoCode"),
            });
          } else {
            response = await API.getTotal({
              subscriptionId: id,
            });
          }
          setPromoPrice(response?.data?.data);
          setPromoError("");

          // Create a new PaymentIntent with the new amount
          await createNewPaymentIntent();
        } else {
          if (getValues("promoCode")) {
            response = await API.getTotalGeneral({
              subscriptionId: id,
              promoCode: getValues("promoCode"),
            });
          } else {
            response = await API.getTotalGeneral({
              subscriptionId: id,
            });
          }
          setPromoPrice(response?.data?.data);
          setPromoError("");

          // Create a new PaymentIntent with the new amount
          await createNewPaymentIntent();
        }
      } else {
        setPromoError("Please enter code");
      }
    } catch (error) {
      console.log(error);
      setPromoPrice(null);
      setPromoError(error?.response?.data?.message);
    }
  };

  const onOpenhandle = () => {
    onOpen();
    setRenderIntend(true);
  };

  const getKeys = async (id) => {
    try {
      const response = await API.getPublishKey();
      setStripePromise(loadStripe(response?.data?.publishableKey));

      // Create initial PaymentIntent
      if (authorized) {
        await createNewPaymentIntent();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (renderIntend && authorized) {
      getKeys(id);
    }
  }, [renderIntend, authorized]);

  const [filterMode, setFilterMode] = useState(item?.durationMode);

  useEffect(() => {
    if (item?.duration === 1) {
      setFilterMode("month");
    }
  }, [filterMode]);

  return (
    <>
      <Button
        onPress={onOpenhandle}
        isDisabled={activeSubsription === id}
        className="bg-themeButton-0 interFont w-[220px] !rounded-[96px] text-white hover:bg-themeButtonHover-0 !opacity-100 text-[16px] px-6 !py-[26px]     font-semibold"
      >
        {activeSubsription === id ? "Current Plan" : text}
      </Button>
      <Modal
        isOpen={isOpen}
        placement="center"
        onOpenChange={onOpenChange}
        className={`${
          authorized ? "max-w-[1050px]  mx-auto " : "max-w-[1050px]  mx-auto"
        } `}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 border-b pb-4">
                <h2 className="text-3xl font-bold text-gray-800">
                  Order Summary
                </h2>
              </ModalHeader>

              <ModalBody className="py-6">
                <div
                  className={` ${
                    authorized ? " " : " "
                  }  flex flex-wrap md:flex-nowrap gap-1 lg:gap-8`}
                >
                  <div className="flex flex-col gap-4 max-w-[450px] w-full pb-8">
                    <div className="flex flex-col items-start gap-1 mb-[20px] md:mb-[50px]  w-full">
                      <h3 className="font-medium text-2xl text-themeButtonHover-0">
                        {item?.name}
                      </h3>
                      <h1 className="text-3xl  font-bold">
                        {" "}
                        ${item?.price?.toFixed(2)}
                      </h1>
                      <p className="font-medium  text-[#ACACAC]">
                        You will be subscribed for {item?.duration} {filterMode}{" "}
                        <br />
                      </p>
                      {/* <p className="text-themeButton-0 ">
                        Questions: 3254
                        <br />
                        Attempts: Unlimited
                      </p> */}
                    </div>

                    {/* <div className="w-full relative">
                      <input
                        type="text"
                        placeholder="Add promo code (Optional)"
                        className={`w-full placeholder:text-[14px] px-4 py-4 border border-gray-300 ${
                          promoError?.length > 5 ? "border-red-400" : ""
                        } 
                            ${promoPrice ? "border-green-600" : ""}
                            rounded-md focus:outline-none  `}
                        {...register("promoCode")}
                      />
                      <button
                        onClick={handleApplyPromo}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-themeButton-0 text-white hover:bg-themeButtonHover-0 text-sm px-4 py-3 rounded-md font-semibold transition duration-300 ease-in-out"
                        type="button"
                      >
                        Apply Promo
                      </button>
                    </div>

                    <div>
                      {error && (
                        <p className="text-sm text-red-500 pl-1">{error}</p>
                      )}
                      {promoError && (
                        <p className="text-sm text-red-500 pl-1">
                          {promoError}
                        </p>
                      )}
                      {promoPrice && (
                        <p className="text-sm text-green-600  ">
                          Promo Code has been applied
                        </p>
                      )}
                    </div> */}

                    <div className="flex flex-col gap-2">
                      {promoPrice && !promoError && (
                        <div className="flex text-sm justify-between items-center gap-4">
                          <span className="font-bold text-[#a7a7a7] block mb-1">
                            Discount:
                          </span>
                          <span className="font-bold text-[#a7a7a7]">
                            $
                            {promoPrice
                              ? parseFloat(item?.price - promoPrice).toFixed(2)
                              : 0}
                          </span>
                        </div>
                      )}

                      <div className="flex justify-between items-center gap-4">
                        <span className="font-bold text-[#757575] block  ">
                          Total due:
                        </span>
                        <span className="font-bold text-[#757575]">
                          $
                          {promoPrice
                            ? parseFloat(promoPrice)?.toFixed(2)
                            : item?.price?.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="w-[2.5px] bg-[#f2e9e9]" />

                  {authorized ? (
                    <div className="w-full  ">
                      <h2 className="text-2xl font-bold text-gray-800 mb-2">
                        Payment Details
                      </h2>

                      {clientSecret && stripePromise && (
                        <Elements
                          key={stripeElementsKey}
                          stripe={stripePromise}
                          options={{ clientSecret }}
                        >
                          <CheckoutForm
                            totalPrice={
                              promoPrice
                                ? parseFloat(promoPrice)?.toFixed(2)
                                : item?.price?.toFixed(2)
                            }
                          />
                        </Elements>
                      )}

                      <div className="flex justify-center gap-1 mt-4 items-center">
                        <img
                          src={stripeLogo}
                          alt="powered by stripe"
                          width={120}
                          height={120}
                        />
                      </div>
                      {/* <div className="flex justify-center gap-1 items-center mt-2">
                        <img src={secure} alt="secure" width={18} height={18} />
                        <p className="  text-sm text-[#a7a7a7]">
                          Secure Checkout
                        </p>
                      </div> */}
                    </div>
                  ) : (
                    <div className="w-full">
                      <StepSignUP />
                    </div>
                  )}
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default SubscriptionModal;
