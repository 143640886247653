import React, { useEffect } from "react";
import CheckBox from "../general/CheckBox";
import { Chip } from "@nextui-org/react";

const ChooseCategories = ({
  freeTrial,
  categories,
  register,
  errors,
  setValue,
  selectAll,
  setSelectAll,
}) => {
  const handleSelectAll = (isChecked) => {
    setSelectAll(isChecked);
    categories.forEach((category) => {
      setValue(`categories.${category.id}`, isChecked);
    });
  };

  useEffect(() => {
    if (freeTrial) {
      handleSelectAll(true);
    }
  }, [freeTrial]);

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    handleSelectAll(isChecked);
  };

  return (
    <div className="flex flex-col gap-2">
      {freeTrial && (
        <p className="flex justify-center font-semibold text-[13px] py-4 text-themeButton-0">
          Subject selection is only available with a paid subscription!
        </p>
      )}

      <div className="bg-themeGray-0 rounded-[8px] p-2">
        <div className="checkbox-wrapper-13 flex justify-center items-center w-fit">
          <input
            id="select-all"
            type="checkbox"
            disabled={freeTrial}
            checked={selectAll}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="select-all" className="px-3 font-semibold">
            Select All
          </label>
        </div>
      </div>
      {categories?.map((item) => (
        <CheckBox
          key={item.id}
          register={register}
          errors={errors}
          id={item.id}
          name={item.name}
          label={item.name}
          freeTrial={freeTrial}
        />
      ))}
    </div>
  );
};

export default ChooseCategories;
