import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HiMiniMinusCircle } from "react-icons/hi2";
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { UserProfile } from "../../assets";
import { formatLastLogin } from "../../utils/date";
import { errorToast, successToast } from "../../hooks/useToast";
import { API } from "../../api";
import { Button, Chip } from "@nextui-org/react";
import { setUser, updateUser } from "../../store/slices/userSlice";
import LinkComponent from "../../components/general/LinkComponent";
import LinkComponent2 from "../../components/general/LinkComponent2";
import MetaData from "../../components/seo/MetaData";

const MyProfilePage = () => {
  const user = useSelector((state) => state.user.user);

  const last5Activities = user?.lastLogin?.slice(-5);

  const getMapSrc = (country) => {
    const countryEncoded = encodeURIComponent(country);
    const baseUrl = "https://www.google.com/maps/embed/v1/search";
    const params = `?q=${countryEncoded}`;
    return `${baseUrl}${params}`;
  };

  const mapSrc = getMapSrc(user?.country);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      setImage(file);

      reader.readAsDataURL(file);
    }
  };

  const handleProfileUpload = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("image", image);

      const response = await API.uploadImage(formData);
      const url = response?.data?.data;

      if (response?.data?.status?.code === 200) {
        const response = await API.updateUser({
          imageUrl: url,
        });
        dispatch(updateUser({ imageUrl: response?.data?.data?.imageUrl }));
      }

      setImagePreview(null);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);

      errorToast(error, "Can not upload image at the moment");
    }
  };

  return (
    <div className="flex gap-4 flex-col">
      <MetaData
        title={"User Profile - Qbank Model"}
        description={
          "Update and manage your profile details on Qbank Model. Ensure your information is accurate for seamless exam preparation."
        }
        keywords={
          "user profile, Qbank Model account, profile management, exam preparation"
        }
      />
      <div className="profile-header  py-4   interFont flex items-center w-full justify-between  ">
        <h2 className=" text-2xl interFont capitalize font-bold ">
          Your Profile{" "}
        </h2>
      </div>
      <div className="flex gap-4 justify-start flex-col md:flex-row">
        <div className="w-full md:w-1/4 ">
          <div className="user relative" layout-sm layout-gt-sm="column">
            {imagePreview ? (
              <>
                <img
                  src={imagePreview}
                  alt="user avatar"
                  className="user-avatar w-full min-h-[250px] rounded-md"
                />
                <div className="user-avatar w-full h-full rounded-md bg-themePrimary-0 group transition-all duration-200 overflow-hidden">
                  <div className="overlay w-full h-full bg-black opacity-0 hover:opacity-15 absolute top-0 left-0 rounded-md"></div>
                  <div className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                    <div className="buttons relative">
                      <div
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        }
                      >
                        <span className="cursor-pointer">
                          <HiMiniMinusCircle className="text-white text-3xl absolute transform translate-x-[-50%] translate-y-[-50%] opacity-65" />
                        </span>
                      </div>
                      <div className="opacity-0 group-hover:opacity-100">
                        <span className="   absolute transform translate-x-[-50%] translate-y-[150%]  text-white">
                          <Button
                            isLoading={loading}
                            onClick={handleProfileUpload}
                            className="px-5 py-2 rounded-md bg-themePrimary-0 text-white "
                          >
                            Upload
                          </Button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  onClick={() => document.getElementById("fileInput").click()}
                  className="user-avatar w-full h-full rounded-md bg-themePrimary-0 group transition-all duration-200 overflow-hidden"
                >
                  <div className="profile-img">
                    <img
                      src={user?.imageUrl || UserProfile}
                      alt="UserProfile"
                      className="rounded-md h-full bg-cover object-center "
                    />
                  </div>
                  <div className="overlay w-full h-full bg-black opacity-0 hover:opacity-15 absolute top-0 left-0 rounded-md"></div>
                  <div className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                    <div className="buttons relative">
                      <div className="group-hover:opacity-0">
                        <span className="cursor-pointer">
                          <HiMiniMinusCircle className="text-white text-3xl absolute transform translate-x-[-50%] translate-y-[-50%] opacity-65" />
                        </span>
                      </div>

                      <div className="opacity-0 group-hover:opacity-100">
                        <span className="cursor-pointer">
                          <FaPlusCircle className="text-white text-3xl absolute transform translate-x-[-50%] translate-y-[-50%]" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <input
              type="file"
              id="fileInput"
              className="hidden"
              onChange={handleImageChange}
            />
          </div>
          <div className="activity mt-4">
            <h4>Activity</h4>
            {last5Activities?.length > 0 ? (
              <div className="list">
                <ul>
                  {last5Activities?.map((item, index) => (
                    <li
                      key={index}
                      className="text-xs border-y-1 first:border-t-0 last:border-b-0 py-1"
                    >
                      <span className="font-semibold">
                        Your last login was at :
                      </span>{" "}
                      {formatLastLogin(item?.date)}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              "No recent Activities"
            )}
          </div>
        </div>
        <div className="w-full md:w-3/4 p-2 md:p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2">
            <div className="border-b-1 border-black border-dashed py-2">
              {user.firstName}
            </div>
            <div className="border-b-1 border-black border-dashed py-2">
              {user.lastName}
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2">
            <div className="border-b-1 border-black border-dashed py-2">
              {user.email}
            </div>
            <div className="border-b-1 border-black border-dashed py-2">
              {user.country}
            </div>

            <LinkComponent2
              to={"/user/dashboard/change-password"}
              text={"Change Password"}
            />
          </div>
          {/* <div className="google-map my-6">
            <iframe
              src={mapSrc}
              width="100%"
              height="450"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="User Location"
            ></iframe>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MyProfilePage;
