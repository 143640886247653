import React, { useEffect, useState } from "react";
import {
  Navbar,
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Button,
} from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LinkComponent from "../general/LinkComponent";
import UserDropDown from "./UserDropDown";
import { deleteCookie } from "../../hooks/useCookies";
import { logoutUser } from "../../store/slices/userSlice";
import { successToast } from "../../hooks/useToast";
import { menuItems } from "../../data/header";
import { logo } from "../../assets";
import DiscountBar from "./DiscountBar";
import { clearExam } from "../../store/slices/userExam";

const MobileHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const handleLogout = () => {
    deleteCookie("token");
    dispatch(logoutUser());
    successToast("Successfully logged out");
  };

  const handleLinkClick = (link) => {
    navigate(link);
    setIsMenuOpen(false);
  };

  const [padding, setPadding] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setPadding(false);
      } else {
        setPadding(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleExamPause = async () => {
    try {
      dispatch(clearExam());
      navigate("/user/dashboard/exam");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="block md:hidden">
        {!location.pathname.startsWith("/user") && <DiscountBar />}
      </div>

      <Navbar
        isMenuOpen={isMenuOpen}
        onMenuOpenChange={setIsMenuOpen}
        className={`lg:hidden py-2 ${
          location.pathname === "/user/attempt/exam"
            ? "bg-themeBackground-0"
            : ""
        } `}
        isBlurred={false}
      >
        <NavbarContent>
          <NavbarMenuToggle
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
            className={
              location.pathname.startsWith("/user") ? "hidden" : "lg:hidden"
            }
          />
          <Link to={"/"} className="w-full">
            <img
              src={logo}
              alt="logo"
              width={200}
              height={100}
              className="max-w-[150px]"
            />
          </Link>
        </NavbarContent>

        <NavbarContent justify="end">
          <NavbarItem>
            {!user?.authorized ? (
              <div className="flex scale-80 justify-end gap-2 items-center text-[12px] cursor-pointer">
                <LinkComponent varient2={true} to="/sign-in" text="Sign In" />
              </div>
            ) : (
              <div className="flex items-center justify-end gap-3">
                {location.pathname === "/user/attempt/exam" && (
                  <Button
                    className="border-themeButton-0 text-themeButton-0 text-[13px] px-6 !py-[10px] !rounded-[8px] w-fit font-semibold"
                    onClick={handleExamPause}
                    variant="bordered"
                  >
                    Pause
                  </Button>
                )}

                <div className="  sm:mr-0 max-w-[200px] w-full flex justify-end cursor-pointer">
                  <UserDropDown handleLogout={handleLogout} user={user} />
                </div>
              </div>
            )}
          </NavbarItem>
        </NavbarContent>

        <NavbarMenu className={`${padding ? "pt-[80px] " : "pt-[40px]"} `}>
          {menuItems?.map((item, index) => (
            <NavbarMenuItem key={`${item.label}-${index}`}>
              <p
                onClick={() => handleLinkClick(item.to)}
                className="cursor-pointer"
              >
                {item.label}
              </p>
            </NavbarMenuItem>
          ))}
        </NavbarMenu>
      </Navbar>
    </>
  );
};

export default MobileHeader;
