import * as yup from "yup";
const updateProfile = yup.object().shape({
  userName: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
});

const changePassword = yup.object().shape({
  oldPassword: yup.string().required(),
  password: yup.string().required(),
  newPassword: yup
    .string()
    .required("Confirm New Password is required")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});
const verifyOtpSchema = yup.object().shape({
  code: yup.string().required("Please enter 6 digit code"),
});
const forgetPasswordSchema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
});
const resetPasswordSchema = yup.object().shape({
  password: yup.string().required("New Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm New Password is required"),
});
export {
  updateProfile,
  changePassword,
  verifyOtpSchema,
  forgetPasswordSchema,
  resetPasswordSchema,
};
