import React, { useState } from "react";
import DisplayBlogs from "./DisplayBlogs";
import Loader from "../general/Loader";
import BlogsSideBar from "./BlogsSidebar";
import { Helmet } from "react-helmet";
import MetaData from "../seo/MetaData";

const Blogs = ({ fetching, blogs }) => {
  const [blogKey, setBlogKey] = useState(null);

  return fetching ? (
    <Loader />
  ) : (
    <>
      <MetaData
        title={"Qbank Model Blogs - AMC MCQ Exam Insights & Tips"}
        description={
          "Stay updated with the latest AMC MCQ exam insights, tips, and study strategies from Qbank Model. Empower your exam preparation journey."
        }
        keywords={
          "Qbank Model blogs, AMC MCQ exam tips, medical study insights, exam preparation advice"
        }
      />
      <div className="py-8 flex md:flex-nowrap justify-center max-w-[1200px] w-full mx-auto px-3 flex-wrap-reverse gap-16 ">
        <DisplayBlogs blogs={blogs} blogName={blogKey} />
        <BlogsSideBar setBlogKey={setBlogKey} blogs={blogs} />
      </div>
    </>
  );
};
export default Blogs;
