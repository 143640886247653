import React, { useEffect, useState } from "react";
import Blogs from "../../components/blog/Blogs";
import { API } from "../../api";
import { errorToast } from "../../hooks/useToast";

const BlogPage = () => {
  const [fetching, setFetching] = useState(true);
  const [blogs, setBlogs] = useState(null);

  const getData = async () => {
    try {
      const response = await API.getAllBlogs();
      const blogsData = response?.data?.data;
      const reverseBlogs = [...blogsData].reverse();
      setBlogs(reverseBlogs);
      setFetching(false);
    } catch (error) {
      errorToast(error, "Can not fetch blogs");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return <Blogs fetching={fetching} blogs={blogs} />;
};

export default BlogPage;
