import React, { useEffect, useState } from "react";
import StepForm from "./StepForm";
import QuestionComponent from "./QuestionComponent";
import { errorToast, successToast } from "../../../hooks/useToast";
import { API } from "../../../api";
import { useDispatch } from "react-redux";
import { clearExam } from "../../../store/slices/userExam";
import { useNavigate } from "react-router-dom";

const AttemptExam = ({ examData, submittedAnswers }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const length = examData?.examQuestions?.length;
  const dispatch = useDispatch();
  const progress = ((currentIndex + 1) / length) * 100;
  const navigate = useNavigate();
  const [timeConsumed, setTimeConsumed] = useState(null);
  const [pauseLoading, setPauseLoading] = useState(false);

  const [exitExam, setExitExam] = useState(false);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handleQuestionChange = (index) => {
    setCurrentIndex(index);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await API.generateResult({
        userExamId: examData?.id,
      });
      successToast(response);
      setLoading(false);
      dispatch(clearExam());
      navigate(`/user/result?id=${response?.data?.data?.id}`);
    } catch (error) {
      console.log(error);
      setLoading(false);
      errorToast(error, "Can not generate Result");
    }
  };

  useEffect(() => {
    if (exitExam && examData?.examMode === "timeBased") {
      handleSubmit();
    }
  }, [exitExam]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const remainingTime = examData?.time;

  const handleExamPause = async () => {
    setPauseLoading(true);
    try {
      const response = await API.pauseUserExam({
        userExamId: examData?.id,
        timeConsumed: timeConsumed,
      });
      successToast(response?.data?.message);
      setPauseLoading(false);
      dispatch(clearExam());
      navigate("/user/dashboard/exam");
    } catch (error) {
      console.log(error);
      setPauseLoading(false);
      errorToast(error, "Can not pause exam");
    }
  };

  const [currentExplanation, setCurrentExplanation] = useState(null);

  useEffect(() => {
    if (
      currentIndex === 0 &&
      currentExplanation &&
      examData?.examMode === "explanationBased"
    ) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [currentExplanation]);

  return (
    <div className="container flex flex-col gap-12 ">
      <StepForm
        handleNext={handleNext}
        length={length}
        currentIndex={currentIndex}
        handlePrevious={handlePrevious}
        handleSubmit={handleSubmit}
        loading={loading}
        mode={examData?.examMode}
        value={progress}
        questionNumber={currentIndex + 1}
        questionId={examData?.examQuestions[currentIndex]?.questionId}
        totalTime={remainingTime}
        setTimeConsumed={setTimeConsumed}
        setExitExam={setExitExam}
        handleExamPause={handleExamPause}
        pauseLoading={pauseLoading}
        currentExplanation={currentExplanation}
        handleQuestionChange={handleQuestionChange}
        categoryName={examData?.examQuestions[currentIndex]?.categoryName}
        topicName={examData?.examQuestions[currentIndex]?.topicName}
      >
        <QuestionComponent
          categoryName={examData?.examQuestions[currentIndex]?.categoryName}
          topicName={examData?.examQuestions[currentIndex]?.topicName}
          submittedAnswers={submittedAnswers}
          totalTime={remainingTime}
          timeConsumed={timeConsumed}
          userExamId={examData?.id}
          data={examData?.examQuestions[currentIndex]}
          setTimeConsumed={setTimeConsumed}
          mode={examData?.examMode}
          setCurrentExplanation={setCurrentExplanation}
        />
      </StepForm>
    </div>
  );
};

export default AttemptExam;
