import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { formatDate } from "../../../utils/date";

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ graphData }) => {
  console.log();
  const data = {
    // labels: ["Correct", "Wrong"],
    datasets: [
      {
        data: [graphData?.correct, graphData?.wrong],
        backgroundColor: ["#C70048", "#243656"],
        hoverBackgroundColor: ["#243656", "#B30040"],
      },
    ],
  };

  return (
    <div className=" mx-auto    bg-white  rounded-[12px] px-6 py-8 w-full md:w-[40%]">
      <h2 className="text-themeSecondryDark-0 text-xl font-bold text-start capitalize ">
        Last Exam Performance
      </h2>
      <p className="text-themeButton-0 text-sm font-medium mb-6">
        {formatDate(graphData?.userExam?.createdAt)}
      </p>
      <div className="  flex justify-center w-full">
        <div className="max-w-[350px]">
          <Doughnut data={data} />
        </div>
      </div>

      <div className="flex flex-col gap-3 mt-4 ">
        <div className="flex justify-between ">
          <div className="flex justify-start items-center gap-3">
            <div className="w-4 h-4 rounded-full bg-themeButton-0"></div>
            <p className="text-themeSecondryDark-0 text-sm font-bold">
              Correct Answers
            </p>
          </div>
          <p className="font-bold">{graphData?.correct}</p>
        </div>
        <div className="flex justify-between ">
          <div className="flex justify-start items-center gap-3">
            <div className="w-4 h-4 rounded-full bg-themeSecondry-0"></div>
            <p className="text-themeSecondryDark-0 text-sm font-bold">
              Wrong Answers
            </p>
          </div>
          <p className="font-bold">{graphData?.wrong}</p>
        </div>
      </div>
    </div>
  );
};

export default PieChart;
