import React from "react";
import "./error.css";
import LinkComponent from "../components/general/LinkComponent";

const ErrorPage = () => {
  return (
    <div id="oopss">
      <div id="error-text">
        <img
          src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg"
          alt="404"
        />
        <span>404 PAGE</span>
        <p class="p-a">. The page you were looking for could not be found</p>
        <LinkComponent to={"/"} text={"Back To Home"} />
         
      </div>
    </div>
  );
};

export default ErrorPage;
