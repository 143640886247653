import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import { Editor } from "primereact/editor";

const ResultDetailModal = ({ item, number }) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const correctAnswer = item?.options?.find((option) => option.isCorrect)?.name;

  return (
    <>
      <Button
        onPress={onOpen}
        variant="bordered"
        className="border-themeButton-0 text-themeButton-0 text-[13px] px-6 !py-[10px] !rounded-[8px] w-fit font-semibold"
      >
        Details
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        className="max-w-[700px] w-full"
        scrollBehavior={"inside"}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Answer Explanation
              </ModalHeader>
              <ModalBody>
                <div className="flex justify-start items-center gap-3">
                  <p className="font-bold">Submitted Answer:</p>
                  <p className=" ">{item?.userAnswer}</p>
                </div>
                <div className="flex justify-start items-center gap-3">
                  <p className="font-bold">Correct Answer Was:</p>
                  <p className=" ">{correctAnswer}</p>
                </div>
                <Editor
                  value={item?.answerDetails}
                  readOnly
                  style={{ height: "100%", width: "100%" }}
                  headerTemplate={<></>}
                />
              </ModalBody>
              <ModalFooter>
                <div className="flex justify-start w-full">
                <Button variant="bordered" className="border-2 border-themeButton-0 text-themeButton-0"  onPress={onClose}>
                  Close
                </Button>

                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ResultDetailModal;
