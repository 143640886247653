import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import MobileHeader from "./MobileHeader";
import ExamFooter from "./ExamFooter";
import CheckoutHeader from "./CheckoutHeader";

const RootLayout = () => {
  const location = useLocation();

  const isDashboardPath =
    location.pathname.startsWith("/user/dashboard/") ||
    location.pathname === "/checkout";

  const isExamPath =
    location.pathname.startsWith("/user/exam") ||
    location.pathname.startsWith("/user/attempt") ||
    location.pathname.startsWith("/user/result") ||
    location.pathname.startsWith("/user/verify-otp") ||
    location.pathname.startsWith("/user/reset-password") ||
    location.pathname.startsWith("/user/payment/thankyou") ||
    location.pathname.startsWith("/user/setup-profile") ||
    location.pathname.startsWith("/user/forget-password");

  const isCheckout = location.pathname === "/checkout";

  return (
    <>
      <div className="sticky top-0 left-0 right-0 z-[50]">
        {!isDashboardPath && <Header />}
      </div>

      {!isDashboardPath && <MobileHeader />}

      <Outlet />

      {!location.pathname.startsWith("/user") && !isCheckout && <Footer />}
      {isExamPath && <ExamFooter />}
    </>
  );
};

export default RootLayout;