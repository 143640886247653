import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@nextui-org/react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { API } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";

const SetupProfile = () => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);

  const onSubmit = async (formData) => {
    setLoading(true);
    const fullName = getValues("fullName");
    const [firstName, lastName] = fullName?.split(" ");

    try {
      const response = await API.updateUser({
        firstName: firstName || " ",
        lastName: lastName || " ",
        password: formData.password,
      });

      const data = response?.data?.data;

      dispatch(
        setUser({
          ...user,
          firstName: data?.firstName,
          lastName: data?.lastName,
        })
      );

      setLoading(false);
      navigate("/user/dashboard/summary");
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  return (
    <div className="  container    ">
      <div className="bg-[#E8DCD0] !my-14  w-full py-[80px] px-3 h-full rounded-[20px] flex flex-col gap-4 justify-center items-center">
        <h2 className="text-3xl md:text-5xl text-[#202E48] capitalize font-semibold text-center">
          Complete Your Profile
        </h2>

        <p className="text-center text-[#202E48]">
          Add your details to start preparing for your AMC CAT Examination
        </p>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full max-w-[500px]"
        >
          <div className="flex flex-col gap-3 mb-4">
            <label className="text-[#202E48] font-medium">Full name</label>
            <input
              type="text"
              className="w-full py-3 px-3 rounded-[8px] border-1 border-black focus:outline-none"
              {...register("fullName", { required: "Full name is required" })}
            />
            {errors.fullName && (
              <span className="text-red-500 text-sm">
                {errors.fullName.message}
              </span>
            )}
          </div>

          <div className="flex flex-col gap-3 mb-4 relative">
            <label className="text-[#202E48] font-medium">
              Set New Password
            </label>
            <div className="flex items-center">
              <input
                type={showPassword ? "text" : "password"}
                className="w-full py-3 px-3 rounded-[8px] border-1 border-black focus:outline-none"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                })}
              />
              <div
                className="absolute right-3 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <AiFillEyeInvisible size={24} />
                ) : (
                  <AiFillEye size={24} />
                )}
              </div>
            </div>
            {errors.password && (
              <span className="text-red-500 text-sm">
                {errors.password.message}
              </span>
            )}
          </div>

          <Button
            isLoading={loading}
            type="submit"
            className="rounded-[8px] px-6 w-full !py-[18px] text-white text-[14px] font-medium border bg-themeButton-0 hover:bg-themeButtonHover-0 !opacity-100"
          >
            DONE
          </Button>
        </form>
      </div>
    </div>
  );
};

export default SetupProfile;
