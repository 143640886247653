import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordSchema } from "../../../validations/account";
import InputField from "../../general/InputField";
import { Button } from "@nextui-org/react";
import { useQuery } from "../../../hooks/queryParam";
import { errorToast, successToast } from "../../../hooks/useToast";
import { API } from "../../../api";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../general/ButtonComponent";

const ResetPassword = () => {
  const query = useQuery();
  const token = query.get("token");
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(resetPasswordSchema) });

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const respone = await API.resetPassword({
        token: token,
        password: data?.password,
      });
      successToast(respone);
      setLoading(false);
      navigate("/sign-in");
    } catch (error) {
      setLoading(false);
      errorToast(error, "Can not reset at the moment, try again letter");
    }
  };

  return (
    <div className="reset-password-page   w-full ">
      <div className="container">
        <div className="container  mx-auto py-20 px-2.5 sm:px-0 h-[60vh] w-full flex  justify-center items-center">
          <div className="w-[360px] max-w-[750px] min- mx-auto ">
            <div className="form-area">
              <h1 className="text-center text-3xl sm:text-4xl font-normal mb-7">
                Reset Password
              </h1>

              <form
                className="grid grid-col-1 gap-6 my-4"
                onSubmit={handleSubmit(onSubmit)}
              >
                <InputField
                  label="New Password"
                  type="password"
                  placeholder="Enter Password"
                  errors={errors}
                  name="password"
                  register={register}
                />
                <InputField
                  label="Confirm Password"
                  type="password"
                  placeholder="Enter Password"
                  errors={errors}
                  name="confirmPassword"
                  register={register}
                />
                <div className="w-full ">
                  <ButtonComponent
                    loading={loading}
                    text={"Reset"}
                    type={"submit"}
                    isActive={true}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
