import React, { useState } from "react";
import InputField from "../../general/InputField";
import ButtonComponent from "../../general/ButtonComponent";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { API } from "../../../api";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../../../validations/login";
import { errorToast, successToast } from "../../../hooks/useToast";
import { setUser } from "../../../store/slices/userSlice";
import { setCookie } from "../../../hooks/useCookies";
import { Helmet } from "react-helmet";
import MetaData from "../../seo/MetaData";

const LogIn = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) });

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await API.logInUser(data);
      successToast(response?.data?.message);
      setCookie("token", response?.data?.data?.token);
      dispatch(setUser(response?.data?.data));
      setLoading(false);
      if (!response?.data?.data?.otpVerified) {
        navigate("/user/verify-otp");
      } else {
        navigate("/user/dashboard/summary");
      }
    } catch (error) {
      setLoading(false);
      errorToast(error, "Can not log In at the moment");
    }
  };

  return (
    <div className="SignUp ">
      <MetaData
        title={"Qbank Model Login - Access Your AMC MCQ Prep"}
        description={
          "Sign in to your Qbank Model account to access your personalized AMC MCQ exam preparation resources and track your progress."
        }
        keywords={
          "Qbank Model sign in, AMC MCQ exam access, user login, exam preparation account"
        }
      />
      <div className="container w-full  mx-auto">
        <div className="py-6 flex justify-center lg:py-16 gap-7 lg:gap-10">
          <div className="w-full lg:max-w-[550px]  ">
            <h2 className="text-3xl md:text-5xl  capitalize mb-7 font-medium text-center">
              Login
            </h2>
            <form
              className="grid grid-col-1 gap-2"
              onSubmit={handleSubmit(onSubmit)}
            >
              <InputField
                label="Email Address"
                type="email"
                placeholder="Email"
                errors={errors}
                name="email"
                register={register}
              />
              <InputField
                label="Password"
                type="password"
                placeholder="Enter Password"
                errors={errors}
                name="password"
                register={register}
              />

              <div className="w-full  mt-8">
                <ButtonComponent
                  type={"submit"}
                  text="Login"
                  loading={loading}
                  isActive={true}
                />
                <div className="flex justify-between flex-col md:flex-row flex-wrap items-center w-full mt-4">
                  <Link
                    to="/user/forget-password"
                    className="underline text-center table  text-themePrimary-0 hover:text-themeSecondry-0 font-normal"
                  >
                    Forgot Password
                  </Link>
                  <Link
                    to="/sign-up"
                    className="underline text-center table  text-themePrimary-0 hover:text-themeSecondry-0 font-normal"
                  >
                    Don't have an account? Register now
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
