import React from "react";
import { Link } from "react-router-dom";

const LinkComponent = ({ to, text, varient2 = false }) => {
  return (
    <Link
      to={to}
      className={`rounded-[50px] w-fit font-medium ${
        varient2
          ? " bg-white text-themeButton-0 hover:bg-themeButtonHover-0 hover:text-white !opacity-100 "
          : "    text-white bg-themeButton-0 hover:bg-themeButtonHover-0 !opacity-100   "
      }
        border-themeButton-0 text-[14px] transition-all hover:border-themeButtonHover-0  whitespace-nowrap    border-[2px]
          px-6 !py-[10px]`}
    >
      {text}
    </Link>
  );
};

export default LinkComponent;
