const typeOfQuestions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Previously Incorrect",
    value: "incorrect",
  },
  {
    label: "Never Attempted",
    value: "unused",
  },
  {
    label: "Pending and Incorrect",
    value: "unUsedAndIncorrect",
  },
];

const typeOfQuestionsFree = [
  {
    label: "20 Trial Questions ",
    value: "all",
  },

  {
    label: "All Questions (This feature is available in Subscription) ",
    value: "temp",
  },

  {
    label: "Previously Incorrect (This feature is available in Subscription)",
    value: "incorrect",
  },
  {
    label: "Never Attempted (This feature is available in Subscription)",
    value: "unused",
  },
  {
    label: "Pending and Incorrect (This feature is available in Subscription)",
    value: "unUsedAndIncorrect",
  },
];

const disabledKeyTypesQ = ["incorrect", "unused", "unUsedAndIncorrect", "temp"];

const examMode = [
  {
    label: "Time Based",
    value: "timeBased",
  },
  {
    label: "Explanation Based",
    value: "explanationBased",
  },
];
const difficultyMode = [
  {
    label: "Easy",
    value: "easy",
  },
  {
    label: "Moderate",
    value: "moderate",
  },
  {
    label: "Difficult",
    value: "difficult",
  },
];

const questionsPerExamFree = [
  {
    label: "10",
    value: 10,
  },
  {
    label: "20 ",
    value: 20,
  },
  {
    label: "30 (This feature is available in Subscription)",
    value: 30,
  },
  {
    label: "40 (This feature is available in Subscription)",
    value: 40,
  },
  {
    label: "50 (This feature is available in Subscription)",
    value: 50,
  },
];
const questionsPerExam = [
  {
    label: "10",
    value: 10,
  },
  {
    label: "20",
    value: 20,
  },
  {
    label: "30",
    value: 30,
  },
  {
    label: "40",
    value: 40,
  },
  {
    label: "50",
    value: 50,
  },
];

const disabledKeyNumberQ = ["30", "40", "50"];

export {
  typeOfQuestions,
  examMode,
  difficultyMode,
  questionsPerExam,
  typeOfQuestionsFree,
  questionsPerExamFree,
  disabledKeyTypesQ,
  disabledKeyNumberQ,
};
