import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LinkComponent from "../../components/general/LinkComponent";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../store/slices/userSlice";
import MetaData from "../../components/seo/MetaData";
import { API } from "../../api";

const ThankYou = () => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(updateUser({ freeTrial: false }));
  }, [dispatch]);

  const getData = async () => {
    try {
      await API.registerUserTempOTP();

      setLoading(true);
    } catch (error) {
      setLoading(true);

      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="flex h-[60vh] w-full flex-col items-center justify-center gap-6 bg-gray-100 px-4 dark:bg-gray-900">
      <MetaData
        title={"Payment Thank You - Qbank Model"}
        description={
          "Thank you for your payment. Your transaction is complete, and your Qbank Model subscription is active."
        }
        keywords={
          "payment confirmation, Qbank Model transaction, thank you page, subscription active"
        }
      />

      <div className="space-y-2 text-center max-w-[780px] w-full">
        <h1 className="py-4">Thank you for subscribing!</h1>
        <p className="text-lg text-gray-500 dark:text-gray-400">
          Your unlimited access is now active, and you can start your
          preparation for AMC MCQ Examination. If you have any questions or need
          assistance, feel free to reach out on support@qbankmodel.com.{" "}
        </p>
      </div>

      {!user?.firstName ? (
        loading ? (
          <>
            <LinkComponent to="/user/verify-otp" text="Complete your Profile" />
          </>
        ) : null
      ) : (
        <LinkComponent to="/user/dashboard/summary" text="Go to Dashboard" />
      )}
    </div>
  );
};

export default ThankYou;
