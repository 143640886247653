import * as yup from "yup";

const signUpSchema = yup.object().shape({
  fullName: yup.string().required("Please Enter Your Full Name"),
  email: yup.string().email().required("Email is required"),
  password: yup.string().required("Password is required"),
  termconditions: yup
    .bool()
    .oneOf([true], "Please agree to the terms and conditions"),
});

export { signUpSchema };
