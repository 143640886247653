import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    firstName: null,
    lastName: null,
    name: null,
    email: null,
    imageUrl: null,
    hearFrom: null,
    date: null,
    otpVerified: null,
    country: null,
    isBlocked: null,
    lastLogin: null,
    freeTrial: null,
    authorized: false,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const {
        firstName,
        lastName,
        name,
        email,
        imageUrl,
        hearFrom,
        date,
        otpVerified,
        isBlocked,
        country,
        lastLogin,
        freeTrial,
      } = action.payload;
      state.user = {
        firstName,
        lastName,
        name,
        email,
        imageUrl,
        hearFrom,
        date,
        otpVerified,
        country,
        isBlocked,
        lastLogin,
        freeTrial,
        authorized: true,
      };
    },
    updateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    logoutUser: (state) => {
      state.user = initialState.user;
    },

    authorizedUser: (state) => {
      state.user = { authorized: true };
    },
  },
});

export const { setUser, logoutUser, updateUser, authorizedUser } =
  userSlice.actions;
export default userSlice.reducer;
