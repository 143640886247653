import React, { useEffect, useState } from "react";
import AttemptExam from "../../components/exam/attempt/AttemptExam";
import { useSelector } from "react-redux";
import { errorToast } from "../../hooks/useToast";
import { API } from "../../api";
import { useNavigate } from "react-router-dom";

const AttemptExamPage = () => {
  const examData = useSelector((state) => state.exam.examData);
  const [submittedAnswers, setSubmittedAnswers] = useState(null);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const response = await API.getPreviouslySubmittedAnswers(examData?.id);
      setSubmittedAnswers(response?.data?.data);
    } catch (error) {
      console.log(error);
      errorToast(error, "Can not fetch answers");
    }
  };
  useEffect(() => {
    if (!examData) {
      navigate("/user/dashboard/exam");
    } else {
      getData();
    }
  }, []);

  return (
    <div className="w-full md:bg-themeGray-0 rounded-lg">
      <div className="max-w-[1100px] w-full mx-auto ">
        <AttemptExam examData={examData} submittedAnswers={submittedAnswers} />
      </div>
    </div>
  );
};

export default AttemptExamPage;
