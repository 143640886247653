import React from "react";

const CustomProgressBar = ({ value }) => {
  return (
    <div className="custom-progress-container ">
      <div
        className="custom-progress-bar"
        style={{ width: `calc(${value}% - 25px)` }}
      ></div>
      <div
        className="round-indicator transition-all flex justify-center items-center text-[12px] text-white font-bold"
        style={{ left: `calc(${value}% - 10px)` }}
      >
        {Number(value).toFixed(0)}%
      </div>
    </div>
  );
};

export default CustomProgressBar;
