import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { FaStar } from "react-icons/fa";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { AiFillLeftCircle , AiFillRightCircle } from "react-icons/ai";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { testimonialData } from "../../data/homepage";
import Chip from "./Chip";

const TestimonialSlider2 = () => {
  return (
    <div className="xl:py-14 bg-[#E9F6FF] py-10 lg:py-16">
      <div className="container w-full mx-auto">
        <div className="lg:px-0 lg:py-0 flex flex-col gap-5">
          <div className="items-start">
            <div className="heading-area flex flex-col gap-4 items-center">
              <Chip text={"Testimonials"} />
              <h2 className="capitalize text-center">
                What Our Students are Saying About Us
              </h2>
            </div>
          </div>

          <div className="w-full mx-auto mt-4 md:mt-8 relative">
            <Swiper
              slidesPerView={1}
              spaceBetween={5}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              navigation={{
                prevEl: ".arrow-left arrow",
                nextEl: ".arrow-right arrow",
              }}
              modules={[Autoplay, Navigation]}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
              className="mySwiper"
            >
              {testimonialData?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="item h-[300px] py-6 px-6 bg-white group md:max-w-[450px] w-full rounded-[24px]">
                    <div className="rating flex flex-row gap-1">
                      {Array.from({ length: item.rating }).map((_, key) => (
                        <FaStar key={key} className="text-[#FFC600]" />
                      ))}
                    </div>
                    <div className="flex flex-col items-start mt-8">
                      <p className="text-sm h-[66px]">"{item.parag}"</p>
                      <div className="mt-8">
                        <div className="flex justify-start items-center gap-2">
                          <div className="w-[17px] h-[2px] bg-themeButton-0"></div>
                          <p className="font-normal text-themeButton-0">
                            {item.name}
                          </p>
                        </div>
                        <div className="mt-2 justify-start items-center flex gap-1">
                          From{" "}
                          <span>
                            <img src={item?.flag} alt={item?.location} />
                          </span>{" "}
                          {item.location}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="arrow-left arrow absolute left-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer">
              <AiFillLeftCircle 
                className="text-themeButton-0 text-3xl md:hidden block"
             
              />
            </div>
            <div className="arrow-right arrow absolute right-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer">
              <AiFillRightCircle
                className="text-themeButton-0 text-3xl md:hidden block"
             
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSlider2;
