import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const MetaData = ({ title, description, keywords }) => {
  const [path, setPath] = useState(null);

  useEffect(() => {
    if (window) {
      setPath(window?.location.href);
    }
  }, []);

  return (
    <Helmet>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}

      {keywords && <meta name="keywords" content={keywords} />}
      {path && <link rel="canonical" href={path} />}
    </Helmet>
  );
};

export default MetaData;
