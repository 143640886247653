import React, { useEffect, useState } from "react";
import { API } from "../../api";
import { Link } from "react-router-dom";

const DiscountBar = () => {
  // const [discountPromo, setDiscountPromo] = useState(null);
  // const [discountPromoType, setDiscountPromoType] = useState(null);

  // const getData = async () => {
  //   try {
  //     const response = await API.getAllPromo();
  //     const data = response?.data?.data?.find(
  //       (item) => item?.discountAd && item?.isGlobal
  //     );
  //     setDiscountPromo(data);
  //     const type = data?.type === "percentage" ? "%" : "$";
  //     setDiscountPromoType(type);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   // const screenWidth = window.innerWidth;
  //   //  if (screenWidth > 768) {
  //      getData();
  //   // }
  // }, []);

  return (
    <>
      {/* {discountPromo?.name && ( */}
      <div className="w-full  bg-themeButton-0 py-3">
        <p className="text-center text-white text-[12px] md:text-sm capitalize">
          50% Off Premium Plans For a Limited Time -{" "}
          <span className="underline">
            <Link to={"/subscriptions"}>Subscribe Now</Link>
          </span>
        </p>
      </div>
      {/* )} */}
    </>
  );
};

export default DiscountBar;
