import { API } from "../../api";
import { errorToast } from "../../hooks/useToast";
import React, { useEffect, useState } from "react";
import Loader from "../general/Loader";
import Tiptap from "../general/Editor";
import { blogMetaData } from "../../metaData";
import MetaData from "../seo/MetaData";

const BlogSlug = ({ slug }) => {
  const [blog, setBlog] = useState(null);
  const [fetching, setFetching] = useState(true);

  const [meta, setMeta] = useState(null);

  const getData = async () => {
    try {
      const response = await API.getSingleBlogBySlug(slug);
      const blogData = response?.data?.data;

      setBlog(blogData);
      setFetching(false);
    } catch (error) {
      errorToast(error?.response?.data?.message, "Cannot fetch blog");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return fetching ? (
    <Loader />
  ) : (
    <div className="max-w-[1200px] w-full mx-auto py-12 flex flex-col gap-8 px-3 sm:px-0">
      <MetaData title={blog?.metaTitle} description={blog?.metaDescription} />
      <img
        src={blog?.imageUrl}
        width={2000}
        height={1000}
        alt={blog?.imageAltText}
        className="w-full rounded-md"
      />
      <div className="flex flex-col">
        <h1 className="text-4xl">{blog?.name}</h1>
        <p>{blog?.date}</p>
      </div>
      <div className="hidden-editor">
        <Tiptap content={blog?.description} />
      </div>
    </div>
  );
};

export default BlogSlug;
