import { Button, Chip, Progress } from "@nextui-org/react";
import React from "react";
import Timer from "./Timer";
import FeedBackModal from "../Modals/FeedBackModal";
import LaboratoryValues from "../Modals/LaboratoryValues";
import ReportQuestionModal from "../Modals/ReportQuestionModal";
import CustomProgressBar from "./ProgressBar";
import { Editor } from "primereact/editor";
import Loader from "../../general/Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { PrevIcon } from "../../../data/svgs";
import { next, prev } from "../../../assets";

const StepForm = ({
  handleNext,
  length,
  currentIndex,
  handlePrevious,
  handleSubmit,
  loading,
  children,
  value,
  questionNumber,
  mode,
  totalTime,
  setTimeConsumed,
  setExitExam,
  handleExamPause,
  pauseLoading,
  questionId,
  currentExplanation,
  handleQuestionChange,
  categoryName,
  topicName,
}) => {
  return (
    <div className="w-full flex flex-col gap-6 pt-4 pb-6 md:py-16 relative">
      <div className="sticky top-[80px] md:hidden bg-white z-[998] py-4  ">
        <div className="flex justify-between items-center w-full">
          <div className=" flex md:hidden justify-start items-center gap-2 ">
            <p className="font-bold text-sm whitespace-nowrap">Exam Mode</p>
            <div className="bg-themePrimary-0   px-3 !py-2 !rounded-[8px] w-fit   text-white">
              <p className="whitespace-nowrap font-medium text-sm">
                {mode === "explanationBased"
                  ? "Explanation Based"
                  : "Time Based"}
              </p>
            </div>
          </div>

          {mode === "timeBased" && (
            <div className=" block  md:hidden   ">
              <Timer
                totalTime={totalTime}
                setTimeConsumed={setTimeConsumed}
                setExitExam={setExitExam}
              />
            </div>
          )}
        </div>
        <div className=" mt-5">
          <CustomProgressBar value={value} />
        </div>
      </div>

      <div className="hidden md:block">
        <CustomProgressBar value={value} />
      </div>

      <div className="w-full border border-black rounded-[25px] p-6">
        <div className="flex justify-between items-center flex-wrap md:flex-nowrap ">
          <div className="flex flex-wrap justify-between md:justify-start items-center w-full mb-2 md:mb-0  ">
            <h3 className=" font-bold md:font-semibold text-[24px] md:text-[30px] md:pb-3">
              Question # {questionNumber}
            </h3>

            <div className="flex  md:hidden justify-start items-center gap-3">
              <p className="font-bold text-sm whitespace-nowrap">Category</p>
              <p className="font-medium text-white text-sm whitespace-nowrap px-5 py-1 bg-themeSecondryDark-0 rounded-[4px]">
                {categoryName}
              </p>
            </div>
          </div>

          <div className="hidden md:flex flex-wrap md:flex-nowrap justify-center items-center gap-3 pb-2">
            <p className="font-bold text-sm whitespace-nowrap">Exam Mode</p>

            <div className="bg-themePrimary-0 hidden md:block text-[13px] px-6 !py-[10px] !rounded-[8px] w-fit font-semibold text-white">
              <p className="whitespace-nowrap">
                {mode === "explanationBased"
                  ? "Explanation Based"
                  : "Time Based"}
              </p>
            </div>

            {mode === "timeBased" && (
              <div className="hidden md:block">
                <Timer
                  totalTime={totalTime}
                  setTimeConsumed={setTimeConsumed}
                  setExitExam={setExitExam}
                />
              </div>
            )}
          </div>

          <div className=" flex  flex-col md:hidden w-full gap-2">
            <p className="font-bold text-sm whitespace-nowrap">Topic</p>

            <div className="w-full border border-themeButton-0 rounded-[4px] px-3 py-1">
              <p className="text-themeButton-0 font-medium text-sm">
                {topicName}
              </p>
            </div>
          </div>
        </div>

        {children}

        <div className="flex justify-between items-center gap-3 flex-wrap lg:flex-nowrap">
          <div className="hidden  md:flex justify-start items-center gap-3 flex-wrap lg:flex-nowrap">
            <Button
              className="border-themeButton-0 text-themeButton-0 text-[13px] px-6 !py-[10px] !rounded-[8px] w-fit font-semibold"
              onClick={handleExamPause}
              variant="bordered"
              isLoading={pauseLoading}
            >
              Pause
            </Button>

            <LaboratoryValues />
            <ReportQuestionModal questionId={questionId} />
            <FeedBackModal questionId={questionId} />
          </div>
          <div className="flex justify-between w-full md:justify-end items-center gap-3 ">
            <Button
              className="border-themeButton-0 text-themeButton-0 text-[13px] px-6 !py-[10px] !rounded-[8px] max-w-[130px] w-full md:w-fit font-semibold"
              onClick={handlePrevious}
              variant="bordered"
              isDisabled={currentIndex === 0}
            >
              Previous
            </Button>

            {length === currentIndex + 1 ? (
              <Button
                className="bg-themeButton-0  text-white text-[13px] px-6 !py-[10px] !rounded-[8px] max-w-[130px] w-full md:w-fit font-semibold "
                onClick={handleSubmit}
                isLoading={loading}
              >
                Submit
              </Button>
            ) : (
              <Button
                className="bg-themeButton-0  text-white text-[13px] px-6 !py-[10px] !rounded-[8px] max-w-[130px] w-full md:w-fit font-semibold "
                onClick={handleNext}
                isDisabled={currentIndex === length - 1}
              >
                Next
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className="hidden md:flex justify-start items-center gap-2 flex-wrap">
        {Array(length)
          .fill()
          .map((_, index) => (
            <Button
              variant={currentIndex != index ? "bordered" : ""}
              className={
                currentIndex === index
                  ? "bg-themePrimary-0 text-white text-[13px] px-6 !py-[10px] !rounded-[8px] w-fit font-semibold"
                  : "  border-themePrimary-0 bg-white text-[13px] px-6 !py-[10px] !rounded-[8px] w-fit font-semibold text-themeSecondry-0"
              }
              key={index}
              onClick={() => handleQuestionChange(index)}
            >
              Q{index + 1}
            </Button>
          ))}
      </div>

      <div className="relative w-full md:hidden">
        <div className="px-[80px]">
          {" "}
          {/* Add padding equal to button width */}
          <Swiper
            modules={[Navigation]}
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
            spaceBetween={10}
            slidesPerView={3}
            centeredSlides={true}
            loop={true}
            className="mySwiper"
            initialSlide={currentIndex}
            onSlideChange={(swiper) => handleQuestionChange(swiper.realIndex)}
          >
            {Array(length)
              .fill()
              .map((_, index) => (
                <SwiperSlide key={index}>
                  {({ isActive }) => (
                    <div
                      className={`h-10 rounded-[8px] flex items-center justify-center text-center cursor-pointer ${
                        isActive
                          ? "bg-themePrimary-0 text-white"
                          : "border border-themePrimary-0 text-themePrimary-0"
                      }`}
                      onClick={() => handleQuestionChange(index)}
                    >
                      Q{index + 1}
                    </div>
                  )}
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
        <button className="swiper-button-prev !w-[70px] !h-10 !left-0 !mt-0 !top-0 flex items-center justify-center bg-white border border-themeButton-0 rounded-[8px]">
          <img src={prev} alt="prev" className="!w-4 !h-4" />
        </button>
        <button className="swiper-button-next !w-[70px] !h-10 !right-0 !mt-0 !top-0 flex items-center justify-center bg-white border border-themeButton-0 rounded-[8px]">
          <img src={next} alt="prev" className="!w-4 !h-4" />
        </button>
      </div>

      <div className=" flex flex-col gap-4  md:hidden">
        <div className="flex justify-between gap-4">
          <Button
            className="bg-themeButton-0 text-white text-[13px] px-6 !py-[10px] !rounded-[8px]    w-full md:w-fit font-semibold"
            onClick={handleExamPause}
            isLoading={pauseLoading}
          >
            Pause
          </Button>

          <LaboratoryValues />
        </div>

        <div className="flex justify-between gap-4">
          <FeedBackModal questionId={questionId} />
          <ReportQuestionModal questionId={questionId} />
        </div>
      </div>

      {currentExplanation && mode === "explanationBased" && (
        <div className="w-full border border-black rounded-[25px] p-6">
          <div className="flex flex-col gap-2">
            {/* <h3 className="font-semibold text-[30px] ">
              Correct Answer Explaination
            </h3> */}
            <Editor
              value={currentExplanation?.answerDetails}
              readOnly
              style={{ height: "100%" }}
              headerTemplate={<></>}
            />
          </div>
          {currentExplanation?.referenceDetails && (
            <div className="flex flex-col gap-2">
              {/* <h3 className="font-semibold text-[30px] ">References</h3> */}
              <Editor
                value={currentExplanation?.referenceDetails}
                readOnly
                style={{ height: "100%" }}
                headerTemplate={<></>}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StepForm;
