import React from "react";

const RadioReport = ({ radioOptions, register, name }) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col items-center gap-1">
        {radioOptions?.map((option) => (
          <div
            key={option?.id}
            className="flex justify-start gap-4 items-center w-full"
          >
            <input
              id={option?.id}
              type="radio"
              value={option?.id}
              {...register(name)}
            />
            <label>{option?.name}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioReport;
